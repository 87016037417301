import { TFunction } from 'i18next';
import { MyCountry } from '../models/Country';

export function getCountryByCountryCode(
	countryCode: string,
	t: TFunction<'translation', undefined, 'translation'>,
): MyCountry {
	let country;

	switch (countryCode) {
		case 'IQ':
			country = { name: t<string>('iraq'), code: 'IQ', currencyCode: 'IQD' };
			break;
		case 'LB':
			country = { name: t<string>('lebanon'), code: 'LB', currencyCode: 'LBP' };
			break;
		case 'SA':
			country = { name: t<string>('saudi'), code: 'SA', currencyCode: 'SAR' };
			break;
		case 'EG':
			country = { name: t<string>('egypt'), code: 'EG', currencyCode: 'EGP' };
			break;
		case 'JO':
			country = { name: t<string>('jordan'), code: 'JO', currencyCode: 'JOD' };
			break;
		default:
			country = { name: '', code: '', currencyCode: '' };
	}

	return country;
}
