import React from 'react';
import { initialDirection } from '../assets/config/initial-direction-state';

type Store = {
	directionState: 'ltr' | 'rtl' | undefined;
	setDirectionState: React.Dispatch<React.SetStateAction<'ltr' | 'rtl' | undefined>>;
};

export const DirectionContext = React.createContext<Store>({
	directionState: initialDirection,
} as Store);

export const useDirectionContext = () => React.useContext(DirectionContext);
