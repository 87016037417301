import { useTranslation } from 'react-i18next';
import { OrderType } from '../../types/OrderType';
import './OrderLifeCycleHeader.scss';
import { useMemo } from 'react';
import { Skeleton, notification } from 'antd';
import { CopyFilled } from '@ant-design/icons';

type OrderLifeCycleHeaderProps = {
	orderType: OrderType;
	user: string;
	orderNumber: string;
	openTime: string;
};

function OrderLifeCycleHeader({ orderType, user, orderNumber, openTime }: OrderLifeCycleHeaderProps) {
	const { t } = useTranslation();
	const isSellOrder = useMemo(() => orderType === 'SELL', [orderType]);
	const isDev = window.location.hostname.includes('devb');
	const orderTypeLabel = useMemo(
		() => (isSellOrder ? t<string>(isDev ? 'sell' : 'withdrawal') : t<string>(isDev ? 'buy' : 'deposit')),
		[isSellOrder, t, isDev],
	);

	const copyMyText = (value: string) => {
		let textToCopy = document.getElementById(value) as HTMLInputElement;
		if (textToCopy) {
			navigator.clipboard.writeText(textToCopy.innerHTML);
			notification['success']({ message: '', description: t<string>('copiedSuccessfully') });
		}
	};

	return (
		<section className='order-life-cycle-header-main-wrapper'>
			<div className='order-life-cycle-header-wrapper'>
				{orderNumber ? (
					<>
						<div className='order-life-cycle-left-side'>
							<div className='order-life-cycle-details'>
								<span
									id='order-life-cycle_order-type'
									className='order-life-cycle-type-status mx-2'
									style={{ backgroundColor: isSellOrder ? '#F6465D' : '#33A867' }}
								>
									{orderTypeLabel}
								</span>
								<h3 className='order-life-cycle-user '>
									{t<string>('orderWith')}: <span id='order-life-cycle_order-with'>{user}</span>
								</h3>
							</div>
						</div>
						<div className='order-life-cycle-right-side xss:flex justify-between sm:flex-col '>
							<div className='order-life-cycle-right-side-row xsss:flex xss:flex-col sm:flex sm:flex-row'>
								<div className='mx-1'>{t<string>('orderNumber')}</div>
								<div className='flex mx-1' id='order-life-cycle_order-number'>
									{orderNumber}
								</div>
								<CopyFilled className='ml-2 cursor-pointer xss:w-[20px]' onClick={() => copyMyText('amount')} />
							</div>
							<div className='order-life-cycle-right-side-row xsss:flex xss:flex-col sm:flex sm:flex-row'>
								<div className='label mx-1'>{t<string>('timeCreated')}</div>
								<p id='order-life-cycle_open-time'>{openTime}</p>
							</div>
						</div>
					</>
				) : (
					<Skeleton active paragraph={{ rows: 1 }} />
				)}
			</div>
		</section>
	);
}

export default OrderLifeCycleHeader;
