import { TableColumnsType } from 'antd';
import { TFunction } from 'i18next';
import { WalletAccount } from 'pages/Wallet/types/WalletAccount';
import { Link } from 'react-router-dom';

export const ColumnsFactory = ({ t }: { t: TFunction }): TableColumnsType<WalletAccount> => [
	{
		key: 'currency',
		dataIndex: 'currency',
		title: t<string>('currency'),
		render(value, _record, i) {
			return <span id={`wallet-accounts_currency_${i}`}>{value}</span>;
		},
	},
	{
		key: 'number',
		dataIndex: 'number',
		title: t<string>('WALLET_NUMBER'),
		render: (value, _record, i) => (
			<Link id={`wallet-accounts_number_${i}`} className='text-black underline' to={`/wallet?walletNumber=${value}`}>
				{value}
			</Link>
		),
	},
	{
		key: 'balance',
		dataIndex: 'balance',
		title: t<string>('balance'),
		render: (value, record, i) => (
			<div id={`wallet-accounts_balance_${i}`}>
				{value} {record.currency}
			</div>
		),
	},
	{
		key: 'onHold',
		dataIndex: 'onHold',
		title: t<string>('ON_HOLD_BALANCE'),
		render: (value, record, i) => <div id={`wallet-accounts_onHold_${i}`}>{`${value} ${record.currency}`}</div>,
	},
];
