import { useMemo } from 'react';
import { OrderStateType } from '../../../types/OrderStateType';
import { getStateConfiguration } from '../../../helpers/getStateConfiguration';
import { useTranslation } from 'react-i18next';
import './State.scss';

function State({ orderState, size }: { orderState: OrderStateType; size: 'big' | 'small' }) {
	const { t } = useTranslation();
	const stateConfig = useMemo(() => getStateConfiguration(orderState, t), [t, orderState]);

	return (
		<span
			id='order-life-cycle_order-state'
			className='state'
			style={{
				color: stateConfig.color,
				backgroundColor: stateConfig.bgColor,
				border: 'none',
				fontSize: size === 'small' ? '10px' : '14px',
			}}
		>
			{stateConfig.label}
		</span>
	);
}

export default State;
