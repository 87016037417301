import { useMemo } from 'react';
import { OrderStateType } from '../../types/OrderStateType';
import StateExplain from './StateExplain/StateExplain';
import StateTimer from './StateTimer/StateTimer';
import Stepper from './Stepper/Stepper';
import './OrderLifeCycleState.scss';
import { OrderType } from '../../types/OrderType';
import { Skeleton } from 'antd';

const stepperStates: OrderStateType[] = ['OPENED', 'TRANSFERRED', 'COMPLETED'];

type OrderLifeCycleStateProps = {
	orderType: OrderType;
	orderState: OrderStateType;
	isMerchant: boolean;
	expireRemainingDuration: number;
	client: string;
	merchant: string;
	orderNo: string;
	getOrderDetails: () => void;
};

function OrderLifeCycleState({
	orderState,
	isMerchant,
	orderType,
	expireRemainingDuration,
	client,
	merchant,
	orderNo,
	getOrderDetails,
}: OrderLifeCycleStateProps) {
	const showStepper = useMemo(() => stepperStates.includes(orderState), [orderState]);

	return (
		<div className='order-life-cycle-state-main-wrapper'>
			<div className='order-life-cycle-state-wrapper'>
				{orderState ? (
					<>
						<div className='state-left-side w-[41%]'>
							{showStepper ? (
								<Stepper isMerchant={isMerchant} orderType={orderType} orderState={orderState as OrderStateType} />
							) : (
								<StateExplain orderState={orderState as OrderStateType} isMerchant={isMerchant} />
							)}
						</div>
						<div className='state-right-side'>
							<StateTimer
								orderState={orderState as OrderStateType}
								expireRemainingDuration={expireRemainingDuration}
								client={client}
								merchant={merchant}
								isMerchant={isMerchant}
								orderNo={orderNo}
								getOrderDetails={getOrderDetails}
							/>
						</div>
					</>
				) : (
					<Skeleton active paragraph={{ rows: 1 }} />
				)}
			</div>
		</div>
	);
}

export default OrderLifeCycleState;
