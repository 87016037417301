import React, { MouseEvent, useEffect, useState } from 'react';
import { CameraFilled, CloseCircleOutlined, StarFilled } from '@ant-design/icons';
import LivePhotoodal from '../modals/LivePhotoModal';
import { useTranslation } from 'react-i18next';

type Props = {
	setCapturedImage: (image: string | null) => void;
};
const UploadLivePicture = (props: Props) => {
	const { t } = useTranslation();
	const [livePhotoModal, setLivePhotoModal] = useState(false);
	const [capturedImage, setCapturedImage] = useState<string | null>(null);

	const removeImage = (e: MouseEvent<HTMLElement>) => {
		e.stopPropagation();
		setCapturedImage(null);
	};

	useEffect(() => {
		props.setCapturedImage(capturedImage);
	}, [capturedImage]);
	return (
		<div className='max-w-lg mt-4 flex xss:flex-col md:flex-row justify-between items-center gap-2'>
			<div
				className=' flex flex-col items-center justify-center w-48 h-36 bg-[#d9d9d9]  cursor-pointer relative '
				onClick={() => setLivePhotoModal(true)}
			>
				<CloseCircleOutlined
					id='upload-live-pic_remove-img-button'
					className='absolute top-1 right-2 text-red-600'
					onClick={removeImage}
				/>
				{capturedImage ? (
					<img src={capturedImage} alt='Captured' width={'100%'} height={'100%'} />
				) : (
					<div className='text-center'>
						<CameraFilled className='text-3xl text-[#aba4a4]' />
						<p className='mt-1 text-gray-500'>
							<StarFilled className='h-[6px] w-[6px] text-red-600 -mt-6' />
							{t('UploadLivePicture')}
						</p>
					</div>
				)}
			</div>
			{/* Tips Section */}
			<div className='md:w-[60%] xss:w-full'>
				<h3 className='font-semibold text-gray-700 text-sm my-2'>{t('Tips')}:</h3>
				<ul className='list-disc list-inside text-gray-600 text-[10px] px-0'>
					<li>{t('tipOne')}</li>
					<li>{t('tipTwo')}</li>
					<li>{t('tipThree')}</li>
					<li>{t('tipFour')}</li>
				</ul>
			</div>
			{livePhotoModal && (
				<LivePhotoodal open={livePhotoModal} setOpen={setLivePhotoModal} setCapturedImage={setCapturedImage} />
			)}
		</div>
	);
};

export default UploadLivePicture;
