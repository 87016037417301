import { TFunction } from 'i18next';

export function getStepperLabels(
	t: TFunction<'translation', undefined, 'translation'>,
	isMerchant: boolean,
	isSellOrder: boolean,
) {
	return (isMerchant && isSellOrder) || (!isMerchant && !isSellOrder)
		? [
				{ title: '-', description: t<string>('transferPaymentToSeller') },
				{ title: '-', description: t<string>('pendingSellerToReleasePayment') },
				{ title: '-', description: t<string>('completed') },
		  ]
		: [
				{ title: '-', description: t<string>('receivingPaymentFromBuyer') },
				{ title: '-', description: t<string>('releasePaymentToBuyer') },
				{ title: '-', description: t<string>('completed') },
		  ];
}
