// NoNotificationsCard.tsx
import React from 'react';
import { Card, Row, Col, Spin } from 'antd';
import { t } from 'i18next';

const NoNotificationsCard: React.FC<{ loading: boolean }> = ({ loading }) => {
	return (
		<Card className='no-notifications-card' style={{ width: '300px' }}>
			<Row align='middle' className='notification-content text-center'>
				<Col className='mx-auto pt-24 w-full' span={12}>
					{loading ? (
						<Spin spinning={true}></Spin>
					) : (
						<>
							<div className='mx-auto pt-8 flex justify-center'>
								<svg width='29' height='27' viewBox='0 0 29 27' fill='none' xmlns='http://www.w3.org/2000/svg'>
									<path
										d='M0.838935 0.0510445C0.359543 0.170893 0.649 0.658274 0 1.1856C0 1.61706 0.0639188 1.68896 2.89233 4.23773C4.19467 5.42023 5.28129 6.41097 5.30526 6.45092C5.32124 6.48288 5.28129 6.72257 5.21737 6.98624C5.11351 7.36975 5.08155 8.17672 5.03361 11.0611C5.00165 13.7856 4.9617 14.7204 4.87381 14.9281C4.81788 15.08 4.39442 15.7032 3.93101 16.3184C2.88434 17.7246 2.76449 17.9723 2.7565 18.8112C2.7565 19.8179 3.17996 20.6249 4.0189 21.2002C4.76195 21.7115 5.22536 21.8074 6.95916 21.8074C7.98985 21.8074 8.46924 21.8314 8.46924 21.8953C8.46924 21.9432 8.56512 22.2229 8.68497 22.5105C10.1072 26.09 14.7493 27.3923 18.1609 25.1711C19.1916 24.5 20.1664 23.2056 20.446 22.143L20.5419 21.8074H21.4368H22.3316L24.5928 23.8688C25.8392 24.9954 27.0057 26.0021 27.1895 26.098C27.7807 26.4255 28.4599 26.2737 28.8194 25.7304C29.0191 25.4428 29.0591 24.9474 28.9233 24.6838C28.8753 24.5959 27.8606 23.6451 26.6621 22.5585C25.4717 21.4798 24.3051 20.4092 24.0734 20.1775C23.8497 19.9538 23.5061 19.6422 23.3224 19.4904C23.0427 19.2586 14.8771 11.908 12.1845 9.46309C11.793 9.09556 9.55586 7.08212 7.23082 4.98078C4.89778 2.87146 2.7565 0.945908 2.47685 0.682243C1.77375 0.0510445 1.4142 -0.0927734 0.838935 0.0510445ZM11.2178 11.7722C13.1113 13.482 15.716 15.839 17.0104 17.0055C18.3048 18.172 19.4713 19.2267 19.6071 19.3465L19.8548 19.5623L12.8397 19.5703C5.15345 19.5703 5.48104 19.5942 5.28129 19.0989C5.22536 18.963 5.20139 18.7793 5.23335 18.6754C5.26531 18.5795 5.61687 18.0682 6.01636 17.5408C6.83132 16.4702 7.03107 16.1266 7.28675 15.4475C7.45453 14.9921 7.46252 14.8483 7.49448 11.7162C7.51845 8.70405 7.52644 8.46436 7.65428 8.56024C7.72619 8.61617 9.33215 10.0623 11.2178 11.7722ZM17.8973 21.9113C17.8973 22.095 17.4498 22.7183 17.0663 23.0698C15.4204 24.5799 12.5201 24.2283 11.3296 22.3747C11.2098 22.1749 11.1059 21.9672 11.1059 21.9113C11.1059 21.8314 11.7611 21.8074 14.5016 21.8074C17.2421 21.8074 17.8973 21.8314 17.8973 21.9113Z'
										fill='#333333'
									/>
									<path
										d='M13.1873 0.0752354C11.6213 0.29895 9.74367 1.01804 9.43207 1.52939C8.99263 2.25646 9.5679 3.19128 10.4548 3.19128C10.6785 3.19128 11.03 3.0954 11.4136 2.91962C13.0834 2.19255 14.6574 2.06471 16.4472 2.52013C18.4127 3.0155 20.0426 4.30986 20.8895 6.02767C21.4328 7.13027 21.4408 7.15424 21.4888 11.261C21.5447 15.2879 21.5367 15.184 22.0241 16.1428C22.4156 16.9178 22.839 17.2534 23.4303 17.2534C24.3571 17.2534 24.9643 16.3745 24.5329 15.6554C23.9816 14.7206 24.0215 15.0322 23.9656 10.9814C23.9257 7.56173 23.9097 7.2661 23.7499 6.69083C23.3344 5.18874 22.5754 3.8784 21.4888 2.79977C20.1465 1.47346 18.4846 0.602566 16.5031 0.195082C15.752 0.0352859 13.9224 -0.0286331 13.1873 0.0752354Z'
										fill='#333333'
									/>
								</svg>
							</div>
							<h6 className='text-center text-lg w-full mt-0'>{t('noNotificationsFound')}</h6>
						</>
					)}
				</Col>
			</Row>
		</Card>
	);
};

export default NoNotificationsCard;
