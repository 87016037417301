import { InfoCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { CompanyName } from 'constant/fallback-config';
import { useTranslation } from 'react-i18next';
import { useConfigurationContext } from 'store/configurationContext';

export function CancelDialog({
	showCancelDialog,
	setShowCancelDialog,
	setShowCancelReason,
}: {
	showCancelDialog: boolean;
	setShowCancelDialog: (value: boolean) => void;
	setShowCancelReason: (value: boolean) => void;
}) {
	const { t } = useTranslation();
	const { configurationState } = useConfigurationContext();
	const Company_Name = configurationState.find((item) => item.configKey === 'Company_Name')?.value || '';
	return (
		<Modal
			title={
				<div>
					<InfoCircleOutlined style={{ fontSize: '40px', textAlign: 'center', margin: '5px auto', display: 'block' }} />
					<h4 className='m-0 p-0 text-center'>{t<string>('cancelThisOrder')}</h4>
				</div>
			}
			open={showCancelDialog}
			onCancel={() => setShowCancelDialog(false)}
			footer={null}
		>
			<p>{t<string>('inzoDoesnNotProvideAutomaticRefunds', { comapnyName: Company_Name })}</p>
			<button
				id='order-life-cycle_action-button-dialog_cancel'
				className='form-btn'
				style={{ cursor: 'pointer', margin: '0 auto', maxWidth: '100px', height: '36px', fontWeight: 400 }}
				onClick={() => {
					setShowCancelReason(true);
					setShowCancelDialog(false);
				}}
			>
				{t<string>('confirm')}
			</button>
		</Modal>
	);
}
