import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import TimeAgo from 'javascript-time-ago';

import en from 'javascript-time-ago/locale/en.json';
import ar from 'javascript-time-ago/locale/ar.json';
import pl from 'javascript-time-ago/locale/pl.json';
import fr from 'javascript-time-ago/locale/fr.json';

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ar);
TimeAgo.addLocale(pl);
TimeAgo.addLocale(fr);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
