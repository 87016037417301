import { OrderStateType } from '../types/OrderStateType';

export function getStepperValue(orderState: OrderStateType) {
	switch (orderState) {
		case 'OPENED':
			return 0;
		case 'TRANSFERRED':
			return 1;
		case 'COMPLETED':
			return 2;
	}
}
