import { TFunction } from 'i18next';
import { MerchantFilters } from '../models/Merchants';

export const getSortByValue = (sortBy: string, t: TFunction<'translation', undefined, 'translation'>) => {
	var key = '';

	switch (sortBy) {
		case MerchantFilters.bestSellers:
			key = t<string>('bestSellers');
			break;
		case MerchantFilters.newSellers:
			key = t<string>('newSeller');
			break;
		case MerchantFilters.maxOrders:
			key = t<string>('maxOrders');
			break;
		case MerchantFilters.minOrders:
			key = t<string>('minOrders');
			break;
		case MerchantFilters.priceAsc:
			key = t<string>('priceLow');
			break;
		case MerchantFilters.priceDesc:
			key = t<string>('priceHight');
			break;
		default:
			key = '';
			break;
	}

	return key;
};
