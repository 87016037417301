import { API_ENDPOINTS } from '../assets/api/endpoints';
import { UserInfo } from '../models/UserInfo';
import axios from 'axios';
const USER_INFO_LOCAL_STORAGE = 'userInfo';
export function refreshTokenForUser() {
	let userInfo = JSON.parse(localStorage.getItem(USER_INFO_LOCAL_STORAGE) || '{}');
	if (userInfo) {
		const expirationDate = new Date(userInfo.expirationTime);
		const currentDate = new Date();
		const hasExpired = expirationDate.getTime() <= currentDate.getTime();
		if (hasExpired) {
			axios
				.post(API_ENDPOINTS.refreshToken, {
					refreshToken: userInfo.refreshToken,
				})
				.then((res) => {
					userInfo = res.data;
					localStorage.setItem(USER_INFO_LOCAL_STORAGE, JSON.stringify(userInfo));
					return userInfo;
				})
				.catch((err) => {
					console.error(err);
					return userInfo;
				});
		}
	}
}
export function setUserInfo(userInfo: UserInfo) {
	localStorage.setItem(USER_INFO_LOCAL_STORAGE, JSON.stringify(userInfo));
}
export function getUserInfo(): UserInfo | undefined {
	const userInfo = JSON.parse(localStorage.getItem(USER_INFO_LOCAL_STORAGE) || '{}');
	return userInfo;
}

export function deleteUserInfo() {
	localStorage.removeItem(USER_INFO_LOCAL_STORAGE);
}
