import { t } from 'i18next';
import { NotificationActionType } from './getNotificationsActionTypes';
import dayjs from 'dayjs';
const DATE_TIME_FORMAT = 'MMM D, YYYY h:mm A';
// Define the contentGenerator function
export function notificationContentGenerator(action: NotificationActionType, properties: Record<string, any>): string {
	// Generate content based on 'action' and 'properties'
	switch (action) {
		case NotificationActionType.NEW_ORDER:
			return t<string>('notificationNewOrderMessage', {
				orderType: t<string>(properties.orderType.toLowerCase()),
				orderNumber: properties.orderNumber,
				amount: properties.amount?.toLocaleString(),
			});
		case NotificationActionType.ORDER_NEW_MESSAGE:
			return t<string>('notificationOrderNewMessage', {
				orderNumber: properties.orderNumber,
				createdAt: dayjs(properties.createdAt).format(DATE_TIME_FORMAT),
			});
		case NotificationActionType.ORDER_EXPIRY_WARNING:
			return t<string>('notificationOrderExpiryWarning', {
				orderNumber: properties.orderNumber,
				orderExpiresTimestamp: dayjs(properties.orderExpiresTimestamp).format(DATE_TIME_FORMAT),
			});
		case NotificationActionType.ORDER_EXPIRED:
			return t<string>('notificationOrderExpired', {
				orderNumber: properties.orderNumber,
			});
		case NotificationActionType.ORDER_STATUS_UPDATE:
			return t<string>('notificationOrderStatusUpdate', {
				orderNumber: properties.orderNumber,
				orderStatusBefore: t<string>(properties.orderStatusBefore.toLowerCase()),
				orderStatusAfter: t<string>(properties.orderStatusAfter.toLowerCase()),
			});
		case NotificationActionType.OFFER_DISABLED:
			return t<string>('notificationOfferDisabled', {
				offerType: t<string>(properties.offerType.toLowerCase()),
				reason: t<string>(properties.reason),
			});
		case NotificationActionType.ACCOUNT_BALANCE_LOW:
			return t<string>('notificationAccountBalanceLow', {
				amount: properties.amount?.toLocaleString(),
			});
		case NotificationActionType.SELL_AVAILABLE_LOW:
			return t<string>('notificationSellAvailableLow', {
				sellAvailable: properties.sellAvailable,
			});
		case NotificationActionType.USER_BLOCK:
			return t<string>('userBlock');
		case NotificationActionType.USER_UNBLOCK:
			return t<string>('userUnblock');
		case NotificationActionType.USER_SUSPEND:
			return t<string>('userSuspend');
		case NotificationActionType.USER_UNSUSPEND:
			return t<string>('userUnsuspend');
		case NotificationActionType.MERCHANT_NEW_REVIEW:
			return properties.reviewText;
		case NotificationActionType.EXCHANGE_RATE_UPDATE:
			return t<string>('exchangeRateUpdate', {
				oldExchangeRate: properties.oldExchangeRate,
				newExhangeRate: properties.newExchangeRate,
				role: properties.role,
			});
		case NotificationActionType.ORDER_REJECTION_WARNING:
			return t<string>('orderRejectionWarning');
		case NotificationActionType.REQUEST_ACTION:
			return t<string>('requestAction', {
				transactionType: t<string>(properties.type),
				amount: properties.amount?.toLocaleString(),
				requestState: properties.requestState,
			});
		case NotificationActionType.NEW_OPERATION:
			if (properties.type == 'TRADING_DEPOSIT') {
				return t<string>('newOperationDeposit', {
					transactionType: t<string>(properties.type),
					amount: properties.amount?.toLocaleString(),
				});
			} else {
				return t<string>('newOperation', {
					transactionType: t<string>(properties.type),
					amount: properties.amount?.toLocaleString(),
				});
			}
		default:
			return '';
	}
}
