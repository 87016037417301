import { CreditCardTwoTone, FundViewOutlined, InfoCircleFilled } from '@ant-design/icons';
import { Button, Grid, Modal, notification, Tooltip } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { WalletType } from '../types/WalletType';
import { TransactionStatus } from '../components/TransactionStatus';
import { WalletStatus } from '../types/WalletStatus';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { CopyFilled } from '@ant-design/icons';
import axios from 'axios';
import { API_ENDPOINTS } from 'assets/api/endpoints';
import { PreviewPhoto } from '../components/PreviewPhoto';
import { useConfigurationContext } from 'store/configurationContext';

type Account = {
	group: string;
	number: number;
	platform: string;
	balance: string;
};

type TransactionReportModalProps = {
	open?: boolean;
	payload?: {
		status: string;
		request: Request;
		date?: string;
		account?: string;
		withdrawCommission?: string;
		wallet?: string;
		amount?: string;
		transactionNumber: string;
		role: string;
		userName: string;
		phone: string;
		email: string;
		type: string;
		quantity: string;
		createdAt: string;
		orderNumber: string;
		currency: string;
		walletNumber: number;
		tradingAccount: Account;
		depositBonus: string;
		withdrawComission: string;
		id: number;
		fileId: string;
		fileLabel: string;
		createdByUserName?: string;
		comment?: string;
	};
	setOpen: (open: boolean) => void;
	title?: string;
};

export const TransactionReportModal = (props: TransactionReportModalProps) => {
	const { xs } = Grid.useBreakpoint();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [photo, setPhoto] = useState('');
	const [showPreviewPhoto, setShowPreviewPhoto] = useState(false);
	const { configurationState } = useConfigurationContext();

	const fields = useMemo(
		() =>
			[
				{ label: t('status'), value: <TransactionStatus status={props.payload?.status as WalletStatus} />, show: true },
				{
					label: t('transactionId'),
					value: (
						<>
							<div id='transactionId'> {props.payload?.transactionNumber}</div>{' '}
							<CopyFilled className='mx-1 cursor-pointer' onClick={() => copyMyText('transactionId')} />
						</>
					),
					show: true,
				},
				{ label: t('date'), value: dayjs(props.payload?.createdAt).format('DD/MM/YYYY hh:mm:ss'), show: true },
				{
					label: t('account'),
					value: (
						<>
							<div id='account'>
								{props.payload?.tradingAccount?.group}| {props.payload?.tradingAccount?.number} |{' '}
								{props.payload?.tradingAccount?.platform} | {props.payload?.tradingAccount?.balance}
							</div>
							<CopyFilled className='mx-1 cursor-pointer' onClick={() => copyMyText('account')} />
						</>
					),
					show: !props.title?.includes('P2P') && !props.title?.includes('ADMIN_WALLET'),
				},
				{
					label: t('withdrawCommission'),
					value: props.payload?.withdrawComission,
					show: !props.title?.includes('P2P') && props.payload?.withdrawComission,
				},
				{
					label: t('depositBouns'),
					value: props.payload?.depositBonus,
					show: !props.title?.includes('P2P') && props.payload?.depositBonus,
				},
				{
					label: t('orderNumber'),
					value: (
						<>
							{' '}
							<div
								onClick={() => navigate(`/order-life-cycle?id=${props.payload?.orderNumber}`)}
								style={{ color: '#409cff', textDecoration: 'underline', cursor: 'pointer', display: 'inline-block' }}
								id='orderNumber'
							>
								{' '}
								{props.payload?.orderNumber}
							</div>{' '}
							<CopyFilled className='mx-1 cursor-pointer' onClick={() => copyMyText('orderNumber')} />
						</>
					),
					show: props.title?.includes('P2P'),
				},
				{
					label: t('Currency/Wallet'),
					value: (
						<>
							<div id='wallet'>
								{props.payload?.currency} | {props.payload?.walletNumber}
							</div>
							<CopyFilled className='mx-1 cursor-pointer' onClick={() => copyMyText('wallet')} />
						</>
					),
					show: true,
				},
				{ label: t('amount'), value: props.payload?.quantity?.toLocaleString(), show: true },
				{ label: t('Comment'), value: props.payload?.comment, show: Boolean(props.payload?.comment) },
				{
					label: t('Created By'),
					value: props.payload?.createdByUserName,
					show: Boolean(props.payload?.createdByUserName),
				},
			].filter((item) => item.show),
		[props.payload],
	);

	const transactionTypeTitle = (type: WalletType) => {
		switch (type) {
			case WalletType.P2P_BUY:
				return t('p2pBuyOrder');
			case WalletType.P2P_SELL:
				return t('p2pSellOrder');
			case WalletType.TRADING_DEPOSIT:
				return t('depositToTradingAccount');
			case WalletType.TRADING_WITHDRAW:
				return t('withdrawFromTradingAccount');
			case WalletType.ADMIN_WALLET_DEPOSIT:
				return t('ADMIN_WALLET_DEPOSIT');
			case WalletType.ADMIN_WALLET_WITHDRAW:
				return t('ADMIN_WALLET_WITHDRAW');
			default:
				return 'P2P';
		}
	};

	const copyMyText = (value: string) => {
		const textToCopy = document.getElementById(value) as HTMLInputElement;
		if (textToCopy) {
			navigator.clipboard.writeText(textToCopy.innerHTML);
			notification['success']({ message: '', description: t<string>('copiedSuccessfully') });
		}
	};

	const downloadFile = () => {
		axios
			.get(
				API_ENDPOINTS.getTransactionImage
					.replace('%transactionNumber%', props?.payload?.transactionNumber ?? '')
					.replace('%fileName%', props?.payload?.fileId ?? ''),
				{
					responseType: 'arraybuffer',
				},
			)
			.then((response) => {
				const base64 = btoa(new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
				setPhoto(base64);
			})
			.catch(console.error);
	};

	useEffect(() => {
		if (
			props.open &&
			props.payload?.transactionNumber &&
			props?.payload?.fileId &&
			props.title === WalletType.TRADING_WITHDRAW
		) {
			downloadFile();
		} else {
			setPhoto('');
		}
	}, [props.open]);
	const withdraw_required_live_picture =
		configurationState.find((item) => item.configKey === 'withdraw_required_live_picture')?.value || false;
	return (
		<>
			<Modal
				open={props?.open}
				footer={null}
				closable={true}
				onCancel={() => props.setOpen(false)}
				width={580}
				destroyOnClose
			>
				<div className='flex flex-col gap-4'>
					<div className='mx-auto'>
						<div className='rounded-full w-16 h-16 bg-[#eee] flex items-center justify-center'>
							<CreditCardTwoTone className='text-2xl' />
						</div>
					</div>
					<div className='font-bold text-[16px]  text-center flex justify-center items-center'>
						<p className='my-0 px-2'>{transactionTypeTitle((props.title as WalletType) || 'P2P_BUY')}</p>
					</div>
					<div>
						<div className='flex mb-2 text-black justify-between'>
							<p className='underline font-bold m-0'>{t('TRANSACTION_REPORT')}</p>
							{props.payload?.fileLabel && withdraw_required_live_picture === 'true' && (
								<Tooltip title='User Live Picture'>
									<div className='text-[#1677ff]'>
										<FundViewOutlined className='text-[25px]' onClick={() => setShowPreviewPhoto(true)} />
									</div>
								</Tooltip>
							)}
						</div>
						<ul className='p-0 list-none flex flex-col gap-2 text-[12px]'>
							{fields.map((field) => (
								<li key={field.label} className='flex items-center justify-between'>
									<span className='text-[12px] '>{field.label}</span>
									<span className='font-bold flex text-end text-[12px]'>{field?.value} </span>
								</li>
							))}
						</ul>
					</div>
					{props.payload?.status !== 'COMPLETED' && props.payload?.status !== 'REVERTED' && (
						<div className='text-xs flex gap-2'>
							<InfoCircleFilled />
							<span>{t('TRANSACTION_REPORT_NOTE')}</span>
						</div>
					)}
					<Button
						id='trx-report-dialog_ok-button'
						onClick={() => props.setOpen(false)}
						className='w-full border-none shadow-none text-white'
						style={{ background: 'linear-gradient(#00AFFF, #0077FF)' }}
						htmlType='button'
						size='large'
						type='primary'
					>
						{t('OK')}
					</Button>
				</div>
			</Modal>
			{showPreviewPhoto && (
				<PreviewPhoto showModal={showPreviewPhoto} setShowModal={setShowPreviewPhoto} photo={photo} />
			)}
		</>
	);
};
