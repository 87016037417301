import { useTranslation } from 'react-i18next';
import { OrderStateType } from '../../../types/OrderStateType';
import './StateExplain.scss';
import { useMemo } from 'react';
import placedOrder from './../../../../../assets/images/orderStates/Placed.png';
import cancelledOrder from './../../../../../assets/images/orderStates/Cancelled.png';
import expiredOrder from './../../../../../assets/images/orderStates/Expired.png';
import rejecteOrder from './../../../../../assets/images/orderStates/Rejected.png';
import appealOrder from './../../../../../assets/images/orderStates/Appeal.png';
import unknownOrder from './../../../../../assets/images/orderStates/Unknown.png';

type StateExplainProps = {
	orderState: OrderStateType;
	isMerchant: boolean;
};

function StateExplain({ orderState, isMerchant }: StateExplainProps) {
	const { t } = useTranslation();
	const explainValues = useMemo(() => {
		switch (orderState) {
			case 'PLACED':
				return isMerchant
					? { label: t<string>('pleaseAcceptTheOrderBeforeItsExpired'), image: placedOrder }
					: { label: t<string>('pleaseWaitUntilMerchantAcceptOrder'), image: placedOrder };
			case 'CANCELLED':
				return { label: t<string>('thisOrderHasBeenCanceled'), image: cancelledOrder };
			case 'EXPIRED':
				return { label: t<string>('thisOrderHasBeenExpired'), image: expiredOrder };
			case 'REJECTED':
				return { label: t<string>('thisOrderHasBeenRejected'), image: rejecteOrder };
			case 'APPEAL':
				return { label: t<string>('thisOrderIsInAppeal'), image: appealOrder };
			default:
				return { label: t<string>('theOrderStateIsUnknown'), image: unknownOrder };
		}
	}, [t, orderState, isMerchant]);

	return (
		<div className='explain-container'>
			<img src={explainValues.image} className='mx-2' alt='explain logo' />
			<p className='explain-label'>{explainValues.label}</p>
		</div>
	);
}

export default StateExplain;
