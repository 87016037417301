import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleOutlined } from '@ant-design/icons';

export function LimitCheckModal({
	isLimitReached,
	setIsLimitReached,
}: {
	isLimitReached: boolean;
	setIsLimitReached: (value: boolean) => void;
}) {
	const { t } = useTranslation();

	return (
		<Modal
			onCancel={() => setIsLimitReached(false)}
			open={isLimitReached}
			footer={null}
			title={
				<div className=''>
					<div className='flex justify-center relative my-4 text-2xl'>
						<ExclamationCircleOutlined />
					</div>
					<p className='flex justify-center mt-[-15px] mb-0 text-[#0F2147] text-[20px]'>{t<string>('limitReached')}</p>
				</div>
			}
		>
			<button
				className='form-btn'
				style={{ cursor: 'pointer', margin: '0 auto', maxWidth: '100px', height: '36px', fontWeight: 400 }}
				onClick={() => setIsLimitReached(false)}
			>
				{t<string>('gotIt')}
			</button>
		</Modal>
	);
}
