import { getUserInfo } from '../../helpers/localStorageHandler';
import { MainState } from '../../models/main-state';

const userInfo = getUserInfo();

export const initialMainState: MainState = {
	id: userInfo?.id ?? '',
	isLoggedIn: Boolean(userInfo?.token) ?? false,
	isMerchant: Boolean(userInfo?.isMerchant) ?? false,
	token: userInfo?.token ?? '',
	email: userInfo?.email ?? '',
	fullName: userInfo?.fullName ?? '',
	countryCode: userInfo?.countryCode ?? '',
	userStatus: userInfo?.userStatus ?? '',
	language: userInfo?.language ?? '',
};

export const resetMainState: MainState = {
	id: '',
	isLoggedIn: false,
	isMerchant: false,
	countryCode: '',
	token: '',
	email: '',
	fullName: '',
	userStatus: '',
	language: '',
};
