interface Props {
	active: boolean;
}

const Buy = ({ active }: Props) => {
	return (
		<svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				id='Vector'
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M6 1.2C8.65098 1.2 10.8 3.34903 10.8 6C10.8 8.65098 8.65098 10.8 6 10.8C3.34903 10.8 1.2 8.65098 1.2 6H0C0 9.31368 2.68629 12 6 12C9.31368 12 12 9.31368 12 6C12 2.68629 9.31368 0 6 0V1.2ZM0.6 2.04853L1.44853 1.2L5.4 5.15147V3.00009H6.6V6.00012V7.20012H3V6.00012H4.55156L0.6 2.04853Z'
				fill={active ? 'white' : '#0F2147'}
			/>
		</svg>
	);
};

export default Buy;
