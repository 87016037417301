export const convertMilliseconds = (ms: number) => {
	// 1 second = 1000 milliseconds
	const seconds = Math.floor(ms / 1000);

	// 1 minute = 60 seconds
	const minutes = Math.floor(seconds / 60);

	// 1 hour = 60 minutes
	const hours = Math.floor(minutes / 60);

	// Remaining seconds
	const remainingSeconds = seconds % 60;

	// Remaining minutes
	const remainingMinutes = minutes % 60;

	// Remaining hours
	const remainingHours = hours;
	return {
		hours: remainingHours,
		minutes: remainingMinutes,
		seconds: remainingSeconds,
	};
};
