import { Alert, Skeleton, Tooltip, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import './OrderDetails.scss';
import { CopyFilled, CreditCardOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { OrderSource } from 'pages/OrderLifeCycle/types/OrderSource';

type OrderDetailsProps = {
	unitPrice: string;
	quantity: string;
	amount: string;
	note: string;
	orderNumber: string;
	source: OrderSource;
};

function OrderDetails({ unitPrice, quantity, amount, note, orderNumber, source }: OrderDetailsProps) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const copyMyText = (value: string) => {
		let textToCopy = document.getElementById(value) as HTMLInputElement;
		if (textToCopy) {
			navigator.clipboard.writeText(textToCopy.innerHTML);
			notification['success']({ message: '', description: t<string>('copiedSuccessfully') });
		}
	};

	return (
		<div className='order-details-wrapper'>
			{note ? <Alert message={t<string>('note')} description={note} type='info' /> : null}
			<h3 className='order-details-title'>
				{t<string>('orderDetails')}
				{source === OrderSource.WALLET && (
					<Tooltip placement='top' title={t<string>('OrderWalletTransaction')}>
						<CreditCardOutlined
							className='text-xl cursor-pointer text-black mx-2'
							onClick={() => navigate(`/wallet?id=${orderNumber}&operation=P2P`)}
						/>
					</Tooltip>
				)}
			</h3>
			{amount !== 'undefined' ? (
				<>
					<div className='details-row'>
						<p>{t<string>('unitPrice')}</p>
						<div className='flex'>
							<p id='order-life-cycle_details_unit-price' className='value'>
								{unitPrice}
							</p>
							<CopyFilled className='mx-2 cursor-pointer' onClick={() => copyMyText('unitprice')} />
						</div>
					</div>
					<div className='details-row'>
						<p>{t<string>('quantity')}</p>
						<div className='flex'>
							<p className='value' id='order-life-cycle_details_quantity'>
								{quantity}
							</p>
							<CopyFilled className='mx-2 cursor-pointer' onClick={() => copyMyText('quantity1')} />
						</div>
					</div>
					<div className='details-row'>
						<p>{t<string>('amount')}</p>
						<div className='flex'>
							<p className='value' id='order-life-cycle_details_amount'>
								{amount}
							</p>
							<CopyFilled className='mx-2 cursor-pointer' onClick={() => copyMyText('amount1')} />
						</div>
					</div>
				</>
			) : (
				<Skeleton active paragraph={{ rows: 1 }} />
			)}
		</div>
	);
}

export default OrderDetails;
