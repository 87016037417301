export function getMenuItems(isLoggedIn: boolean, isMerchant: boolean) {
	const menuItems: unknown[] = [];

	if (!isLoggedIn) {
		return [];
	}

	menuItems.push(
		{ key: 'my-orders', label: 'myOrders' },
		{ key: 'merchants', label: 'merchants' },
		{ key: 'wallet', label: 'WALLET' },
	);

	if (isMerchant) {
		menuItems.push({ key: 'my-offers', label: 'myOffers' });
	}

	return menuItems;
}
