import { CaretDownFilled, SearchOutlined, SyncOutlined } from '@ant-design/icons';
import {
	Button,
	Col,
	Collapse,
	ConfigProvider,
	DatePicker,
	Dropdown,
	Form,
	Grid,
	Input,
	InputNumber,
	Row,
	Select,
	TimeRangePickerProps,
	TreeSelect,
} from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WalletType } from '../types/WalletType';
import { TransactionType } from './TransactionType';
import { TransactionStatus } from './TransactionStatus';
import { WalletStatus } from '../types/WalletStatus';

import dayjs from 'dayjs';
import { MyAccount } from 'models/MyAccounts';

const { RangePicker } = DatePicker;

const NumberOfRowsControl = ({
	numberOfEntries,
	setNumberOfEntries,
}: {
	numberOfEntries: string;
	setNumberOfEntries: (numberOfEntries: string) => void;
}) => {
	const { t } = useTranslation();

	const numberOfEntriesOptions = useMemo(
		() => [
			{ label: '10', key: '10' },
			{ label: '20', key: '20' },
			{ label: '50', key: '50' },
			{ label: '100', key: '100' },
		],
		[],
	);

	const onClick = useCallback(
		({ key }: { key: string }) => {
			setNumberOfEntries(key);
		},
		[setNumberOfEntries],
	);

	return (
		<div className='flex items-center gap-2'>
			<span className='text-[#000D1D99] '>{t('show')}</span>
			<Dropdown menu={{ items: numberOfEntriesOptions, onClick }}>
				<span className='bg-[#F5F5F5] text-[#1E2329] rounded xss:h-8 md:h-10 xss:w-14 md:w-24 flex items-center justify-between px-2 cursor-pointer'>
					<span>{numberOfEntries}</span>
					<CaretDownFilled />
				</span>
			</Dropdown>
			<span className='text-[#000D1D99] '>{t('entries')}</span>
		</div>
	);
};

type WalletFiltersProps = {
	className?: string;
	numberOfEntries: string;
	setNumberOfEntries: (numberOfEntries: string) => void;
	search: string;
	setDateRange: (range: string[]) => void;
	setSearch: (search: string) => void;
	status: string | null;
	setStatus: (status: string | null) => void;
	type: string | null;
	setType: (type: string | null) => void;
	refresh: () => void;
	transactionStatusList: { label: string; value: string }[];
	transactionTypeList: { [K: string]: string[] };
	isP2P: boolean;
	setIsP2P: (isP2P: boolean) => void;
	orderNumber: string;
	setOrderNumber: (orderNumber: string | null) => void;
	setOperation: (operation: string | null) => void;
	operation: string | null;
	setAccount: (account: MyAccount | null) => void;
	account: MyAccount | null;
	accounts: MyAccount[];
};

export const WalletFilters = (props: WalletFiltersProps) => {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const { lg } = Grid.useBreakpoint();
	const [activeKey, setActiveKey] = useState<string[] | string>(lg ? ['1'] : []);

	const transactionStatusList = [
		{
			label: <>{t('all')}</>,
			value: '',
		},
	].concat(
		props.transactionStatusList.map((status) => ({
			label: <TransactionStatus status={status.value as WalletStatus} />,
			value: status.value,
		})),
	);

	const transactionTypeList = [
		{
			label: <>{t('all')}</>,
			value: '',
		},
	].concat(
		// @ts-ignore
		Object.keys(props.transactionTypeList).map((key) => ({
			title: t(key),
			value: key,
			children: props.transactionTypeList[key].map((type) => ({
				title: <TransactionType type={type as WalletType} />,
				value: type,
			})),
		})),
	);

	const onDateRangeChange = useCallback((values: TimeRangePickerProps['value']) => {
		const range = (values || [])?.map((value) => dayjs(value).format('YYYY-MM-DD 00:00:00'));
		props.setDateRange(range);
	}, []);

	const AccountsList = [
		{
			label: <>{t('all')}</>,
			value: '',
		},
	].concat(
		props.accounts.map((account: any) => ({
			label: (
				<>
					{account.accountGroup} | {account.number} | {account.tradingPlatform}
				</>
			),
			value: account.number,
		})),
	);

	useEffect(() => {
		// Open panel by default if large screen (`lg`) is active, otherwise close
		if (lg) {
			setActiveKey(['1']);
		} else {
			setActiveKey([]);
		}
	}, [lg]);
	return (
		<ConfigProvider
			theme={{
				inherit: true,
				components: {
					TreeSelect: {
						nodeSelectedBg: '#fff',
						colorBorder: '#fff',
						fontSize: lg ? 14 : 10,
					},
				},
			}}
		>
			<div className={props.className}>
				<Collapse activeKey={activeKey} onChange={(key) => setActiveKey(key)}>
					<Collapse.Panel header={t('Filter Options')} key='1'>
						<div className='p-4 border border-solid border-black/10 rounded'>
							<Form form={form} layout='vertical'>
								<Row gutter={[16, 0]}>
									<Col xs={24} lg={6} className='w-full'>
										<Form.Item label={t('dateRange')} name='dateRange' className='mb-0'>
											<RangePicker onChange={(values) => onDateRangeChange(values)} className='w-full mb-0' />
										</Form.Item>
									</Col>
									<Col xs={12} lg={6} className='w-full'>
										<Form.Item label={t('status')} name='status' className='min-w-64 mb-0'>
											<Select
												value={props.status}
												defaultValue={t('all')}
												onChange={(e) => props.setStatus(e)}
												options={transactionStatusList}
												className='w-full'
											/>
										</Form.Item>
									</Col>
									<Col xs={12} lg={6} className='w-full'>
										<Form.Item label={t('type')} name='type' className='min-w-64 mb-0'>
											<TreeSelect
												className='w-full'
												onChange={(e) => {
													props.setOrderNumber('');
													props.setAccount(null);
													props.setIsP2P(
														[WalletType.P2P_BUY, WalletType.P2P_REFUND, WalletType.P2P_SELL].includes(e as WalletType),
													);

													if (['P2P', 'TRADING_ACCOUNT', 'ADMIN_OPERATION'].includes(e as string)) {
														props.setType(null);
														props.setOperation(e as string);
													} else {
														props.setType(e);
														props.setOperation(null);
													}
												}}
												value={props.type}
												defaultValue={props.operation || t('all')}
												suffixIcon={null}
												treeDefaultExpandAll
												treeData={transactionTypeList}
											/>
										</Form.Item>
									</Col>
									{(props.operation === 'P2P' || props.type?.includes('P2P')) && (
										<Col xs={24} lg={6} className='w-full'>
											<Form.Item label={t('orderNumber')} name='orderNumber' className='min-w-64 mb-0'>
												<InputNumber
													defaultValue={props.orderNumber}
													value={props.orderNumber}
													onChange={(e) => props.setOrderNumber(e)}
													className='w-full'
												/>
											</Form.Item>
										</Col>
									)}
									{(props.operation === 'TRADING_ACCOUNT' || props.type?.includes('TRADING')) && (
										<Col xs={24} lg={6} className='w-full'>
											<Form.Item name='account' label={t('account')} className='min-w-64 mb-0'>
												<Select
													value={props.account?.number}
													onChange={(e) => props.setAccount(props.accounts.find((acc) => acc.number === e) || null)}
													options={AccountsList}
													showSearch
													filterOption={(inputValue, option: any) =>
														option?.label?.props?.children
															? option?.label?.props?.children?.toString().includes(inputValue.toLowerCase())
															: false
													}
												/>
											</Form.Item>
										</Col>
									)}
								</Row>
							</Form>
						</div>
					</Collapse.Panel>
				</Collapse>
				<Row gutter={[16, 16]} align='middle' className='mt-2'>
					<Col xs={10} lg={12}>
						<NumberOfRowsControl
							numberOfEntries={props.numberOfEntries}
							setNumberOfEntries={props.setNumberOfEntries}
						/>
					</Col>
					<Col xs={14} lg={12} className='flex gap-2 items-center justify-end'>
						<Button
							onClick={props.refresh}
							className='xss:h-8 md:h-10 xss:w-8 md:w-10  border-none flex items-center justify-center bg-[#EAECEF] rounded-[4px]'
						>
							<SyncOutlined />
						</Button>
						<Input
							id='wallet_search-input'
							value={props.search}
							onChange={(e) => props.setSearch(e.target.value)}
							className='xss:w-full sm:w-64 border-none xss:h-8 md:h-10 rounded-md'
							placeholder={t<string>('searchWallet')}
							prefix={<SearchOutlined />}
						/>
					</Col>
				</Row>
			</div>
		</ConfigProvider>
	);
};
