import React from 'react';

type Store = {
	notificationType: string; // Change from '' to string
	setNotificationType: React.Dispatch<React.SetStateAction<string>>;
};

export const NotificationTypeContext = React.createContext<Store>({
	notificationType: '', // Initial value can still be an empty string
	setNotificationType: () => {}, // Just a placeholder for the default value
} as Store);

export const useNotificationTypeContext = () => React.useContext(NotificationTypeContext);
