import { ColumnsType } from 'antd/es/table/interface';
import { TFunction } from 'i18next';
import { MyAccount } from '../models/MyAccounts';

export const getMyAccountsColumns = (t: TFunction<'translation', undefined, 'translation'>) => {
	const columns: ColumnsType<MyAccount> = [
		{
			title: <div className=' text-[#000D1D99] text-sm'>{t<string>('tradingPlatform')}</div>,
			dataIndex: 'tradingPlatform',
			ellipsis: true,
			responsive: ['lg'],
			render: (value, record, i) => {
				return (
					<div id={`my-accounts_tradingPlatform_${i}`} className='items-center inline-block text-[black] text-[14px]'>
						{record.tradingPlatform}
					</div>
				);
			},
		},
		{
			title: <div className=' text-[#000D1D99] text-sm '>{t<string>('accountType')}</div>,
			dataIndex: 'accountType',
			key: 'accountType',
			responsive: ['lg'],
			render: (value, record, i) => {
				return (
					<div id={`my-accounts_accountType_${i}`} className='items-center inline-block text-[#01081E61] text-[14px]'>
						{record.accountGroup}
					</div>
				);
			},
		},
		{
			title: <div className=' text-[#000D1D99] text-sm'>{t<string>('accountNumber')}</div>,
			dataIndex: 'accountNumber',
			key: 'accountNumber',
			responsive: ['lg'],
			render: (value, record, i) => (
				<div id={`my-accounts_accountNumber_${i}`} className='items-center inline-block text-[#01081E61] text-[14px]'>
					{record.number}
				</div>
			),
			ellipsis: true,
		},
		{
			title: <div className='text-[#000D1D99] text-sm'>{t<string>('balance')}</div>,
			dataIndex: 'balance',
			key: 'balance',
			responsive: ['lg'],
			render: (value, record, i) => {
				return (
					<div id={`my-accounts_balance_${i}`} className='text-[16px] font-medium text-lightBlue flex items-center'>
						{record.balance?.toLocaleString()}
						<span className='inline-block mx-1 text-[grey] text-[13px]'>USD</span>
					</div>
				);
			},
		},
		{
			title: <div className='text-[#000D1D99] text-sm'>{t<string>('credit')}</div>,
			dataIndex: 'credit',
			key: 'credit',
			responsive: ['lg'],
			render: (value, record, i) => {
				return (
					<div id={`my-accounts_credit_${i}`} className='text-[16px] font-medium text-lightBlue flex items-center'>
						{record.credit?.toLocaleString()}
						<span className='inline-block px-1 text-[grey] text-[13px]'>USD</span>
					</div>
				);
			},
		},
		{
			title: <div className='text-[#000D1D99] text-sm'>{t<string>('onHoldBalance')}</div>,
			dataIndex: 'onHoldBalance',
			key: 'onHoldBalance',
			responsive: ['lg'],
			render: (value, record, i) => {
				return (
					<div
						id={`my-accounts_onHoldBalance_${i}`}
						className='text-[16px] font-medium text-lightBlue flex items-center'
					>
						{record.onHold ?? 0}
						<span className='inline-block mx-1 text-[grey] text-[13px]'>USD</span>
					</div>
				);
			},
		},
		{
			dataIndex: 'record',
			key: 'record',
			className: 'small-screen-row',
			render: (value, record, i) => {
				return (
					<div
						id={`my-accounts_record_${i}`}
						className='text-xs text-[#01081E99] flex flex-row xs:flex-col mb-1 w-100 justify-between items-center '
					>
						<div className='w-full'>
							<div className='flex flex-row py-2 justify-between'>
								<div className=' text-[#000D1D99] text-sm'>{t<string>('tradingPlatform')}</div>
								<div className='px-3 flex items-center inline-block mx-1 text-[black] text-[14px]'>
									{record.tradingPlatform}
								</div>
							</div>
							<div className='flex flex-row py-2 justify-between'>
								<div className='py-3 text-[#000D1D99] text-sm flex items-center'>{t<string>('accountType')}</div>
								<div className='mx-1 text-[15px] font-medium text-lightBlue flex items-center'>
									<p className='flex items-center inline-block text-[#01081E61] text-[14px]'>{record.accountGroup}</p>
								</div>
							</div>
							<div className='flex flex-row py-2 justify-between'>
								<div className='py-3 text-[#000D1D99] text-sm flex items-center'>{t<string>('accountNumber')}</div>
								<div className='text-[15px] font-medium text-lightBlue flex items-center'>
									<p className='items-center inline-block text-[#01081E61] text-[14px]'>{record.number}</p>
								</div>
							</div>
							<div className='flex flex-row py-2 justify-between'>
								<div className='py-3 text-[#000D1D99] text-sm flex items-center'>{t<string>('balance')}</div>
								<div className='text-[19px] font-medium text-lightBlue flex items-center'>
									{record.balance?.toLocaleString()}
									<span className='inline-block mx-1 text-[grey] text-[13px]'>USD</span>
								</div>
							</div>
							<div className='flex flex-row py-2 justify-between'>
								<div className='py-3 text-[#000D1D99] text-sm flex items-center'>{t<string>('credit')}</div>
								<div className='text-[19px] font-medium text-lightBlue flex items-center'>
									{record.credit?.toLocaleString()}
									<span className='inline-block mx-1 text-[grey] text-[13px]'>USD</span>
								</div>
							</div>
							<div className='flex flex-row py-2 justify-between'>
								<div className='py-3 text-[#000D1D99] text-sm flex items-center'>{t<string>('onHoldBalance')}</div>
								<div className='text-[19px] font-medium text-lightBlue flex items-center'>
									{record.onHold ?? 0}
									<span className='inline-block mx-1 text-[grey] text-[13px]'>USD</span>
								</div>
							</div>
						</div>
					</div>
				);
			},
		},
	];

	return columns;
};
