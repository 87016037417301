import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

export function PreviewPhoto({
	showModal,
	setShowModal,
	photo,
}: {
	showModal: boolean;
	setShowModal: (value: boolean) => void;
	photo: string;
}) {
	const { t } = useTranslation();
	return (
		<Modal onCancel={() => setShowModal(false)} open={showModal} footer={null} width={600}>
			<div className='m-4 text-center'>
				<img
					src={`data:image/jpeg;base64,${photo}`}
					alt=''
					style={{ maxWidth: '100%', height: 'auto', display: 'block', margin: '0 auto' }}
				/>
				<Button
					id='preview-photo_ok-button'
					onClick={() => setShowModal(false)}
					className='w-full mt-4 border-none shadow-none text-white'
					style={{ background: 'linear-gradient(#00AFFF, #0077FF)' }}
					htmlType='button'
					size='large'
					type='primary'
				>
					{t('OK')}
				</Button>
			</div>
		</Modal>
	);
}
