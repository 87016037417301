import './StateTimer.scss';
import { useTranslation } from 'react-i18next';
import { OrderStateType } from '../../../types/OrderStateType';
import { useEffect, useMemo, useState } from 'react';
import contactSupport from './../../../../../assets/images/contactSupport.png';
import { Button, Form, Modal, Tooltip, notification } from 'antd';
import State from '../State/State';
import TextArea from 'antd/es/input/TextArea';
import { API_ENDPOINTS } from '../../../../../assets/api/endpoints';
import axios from 'axios';
import { getUserInfo } from '../../../../../helpers/localStorageHandler';

type StateTimerProps = {
	orderState: OrderStateType;
	expireRemainingDuration: number;
	client: string;
	merchant: string;
	orderNo: string;
	isMerchant: boolean;
	getOrderDetails: () => void;
};

const numberFormatter = (num: number) => {
	return `${num > 9 ? '' : '0'}${num}`;
};
const orderStateWithTimer: OrderStateType[] = ['PLACED', 'OPENED', 'TRANSFERRED'];

function StateTimer({
	orderState,
	expireRemainingDuration,
	client,
	merchant,
	orderNo,
	isMerchant,
	getOrderDetails,
}: StateTimerProps) {
	const { t } = useTranslation();
	const [contactSupportForm] = Form.useForm();
	const [showSupportDialog, setShowSupportDialog] = useState(false);
	const [isContactSupportValid, setIsContactSupportValid] = useState(false);
	const showTimer = useMemo(
		() => expireRemainingDuration && orderStateWithTimer.includes(orderState) && expireRemainingDuration > 0,
		[orderState, expireRemainingDuration],
	);
	const [minutes, setMinutes] = useState('0');
	const [seconds, setSeconds] = useState('0');
	const [currentCountdown, setCurrentCountdown] = useState(expireRemainingDuration || 0);
	const [countdownWorker, setCountdownWorker] = useState<Worker | null>(null);
	const token = getUserInfo()?.token;
	const userName = useMemo(() => getUserInfo()?.fullName, []);
	const clientName = useMemo(() => (isMerchant ? client : userName), [isMerchant, client, userName]);
	const merchantName = useMemo(() => (isMerchant ? userName : merchant), [isMerchant, merchant, userName]);

	useEffect(() => {
		// Create a new worker on each mount
		let worker = new Worker('./countdownWorker.js');

		// Send a message to the worker to set the initial countdown
		worker.postMessage({ type: 'init', countdown: expireRemainingDuration | 0 });

		// Listen for messages from the worker
		worker.onmessage = function (e) {
			if (e.data.type === 'countdown') {
				setCurrentCountdown(e.data.countdown);
			} else if (e.data.type === 'completed') {
				// Handle countdown completion
				expireRemainingDuration && getOrderDetails();
			}
		};

		// Set the worker in state for cleanup
		setCountdownWorker(worker);

		// Clean up the worker when the component unmounts
		return () => {
			worker.terminate();
		};
	}, [expireRemainingDuration, orderState]);

	useEffect(() => {
		setMinutes(numberFormatter(Math.floor((currentCountdown % 3600) / 60)));
		setSeconds(numberFormatter(currentCountdown % 60));
	}, [currentCountdown]);

	const handleSupportFormSubmit = () => {
		axios
			.post(
				API_ENDPOINTS.orderSupport,
				{ client: clientName, merchant: merchantName, orderNo, message: contactSupportForm.getFieldValue('message') },
				{ headers: { Authorization: `Bearer ${token}` } },
			)
			.then(() => {
				notification['success']({ message: '', description: t<string>('requestWasSentSuccessfully') });
				setShowSupportDialog(false);
				setIsContactSupportValid(false);
			})
			.catch((error) => console.error(error));
	};

	return (
		<div className='order-state-timer-container'>
			<div className='state-timer-container'>
				<div className='state-timer-row'>
					<div className='support-container mx-2'>
						<Tooltip placement='topLeft' title={t<string>('contactSupport')}>
							<button className='contact-support-button' onClick={() => setShowSupportDialog(true)}>
								<img src={contactSupport} alt='contact support' />
							</button>
						</Tooltip>
					</div>
					<State orderState={orderState} size='big' />
				</div>
				{showTimer ? (
					<div className='timer-container'>
						{t<string>('expireIn')}:<span>{minutes}</span> : <span>{seconds}</span>
					</div>
				) : null}
			</div>
			<Modal
				title={
					<div className='contact-support-header'>
						<h4 className='m-0 p-0 text-center'>{t<string>('contactSupport')}</h4>
						<div className='text-[#0268D9] text-center text-sm'>
							<span>{t<string>('emailUs')}: </span>
							<span>support@webatts.co</span>
						</div>
					</div>
				}
				onCancel={() => setShowSupportDialog(false)}
				open={showSupportDialog}
				footer={null}
			>
				<Form
					name='basic'
					className='contact-support-form'
					form={contactSupportForm}
					layout='vertical'
					autoComplete='off'
					onValuesChange={() => setIsContactSupportValid(contactSupportForm.getFieldValue('message'))}
				>
					<div>
						<div className='mb-2'>
							<span className='order-info-heading text-sm'>{t<string>('client')}</span>
							<span className='order-detail text-sm font-bold'>: {clientName}</span>
						</div>
						<div className='mb-2'>
							<span className='order-info-heading text-sm'>{t<string>('merchant')}</span>
							<span className='order-detail text-sm font-bold'>: {merchantName}</span>
						</div>
						<div className='mb-2'>
							<span className='order-info-heading text-sm'>{t<string>('orderNumber')}</span>
							<span className='order-detail text-sm font-bold'>: {orderNo}</span>
						</div>
						<div className='contact-support-modal'>
							<Form.Item
								label={t<string>('message')}
								name='message'
								rules={[{ required: true, message: t<string>('messageIsRequired') }]}
							>
								<TextArea rows={4} placeholder={t<string>('writeMessage')} />
							</Form.Item>
						</div>
					</div>
					<Form.Item style={{ margin: 0 }}>
						<Button
							className='form-btn'
							style={{ margin: '0 auto', maxWidth: '100px', height: '36px', fontWeight: 400 }}
							type='primary'
							htmlType='submit'
							disabled={!isContactSupportValid}
							onClick={() => handleSupportFormSubmit()}
						>
							{t<string>('send')}
						</Button>
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
}

export default StateTimer;
