import { Grid } from 'antd';
import { CompanyName, tablLogo } from 'constant/fallback-config';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useConfigurationContext } from 'store/configurationContext';

function MainWrapper({ children }: { children: React.ReactNode }) {
	const { configurationState } = useConfigurationContext();
	const { lg } = Grid.useBreakpoint();
	const faviconUrl = configurationState.find((item) => item.configKey === 'tab_logo')?.value || '';
	const title = configurationState.find((item) => item.configKey === 'Company_Name')?.value || '';
	return (
		<>
			<Helmet>
				{/* General favicon */}
				<link rel='icon' type='image/png' href={faviconUrl} sizes={lg ? '32x32' : '16x16'} />

				{/* Apple touch icon */}
				{/* <link rel='apple-touch-icon' href={faviconUrl} sizes={lg ? '180x180' : '57x57'} /> */}

				{/* Microsoft tiles */}
				<meta name='msapplication-TileImage' content={faviconUrl} />
				<meta name='msapplication-TileColor' content='#ffffff' />
				<title>{title ? `${title} P2P` : ''}</title>
			</Helmet>
			{children}
		</>
	);
}

export default MainWrapper;
