import { Steps } from 'antd';
import { OrderType } from '../../../types/OrderType';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import './Stepper.scss';
import { getStepperLabels } from '../../../helpers/getStepperLabels';
import { OrderStateType } from '../../../types/OrderStateType';
import { getStepperValue } from '../../../helpers/getStepperValue';

import { useEffect } from 'react';
type StepperProps = {
	isMerchant: boolean;
	orderType: OrderType;
	orderState: OrderStateType;
};

function Stepper({ isMerchant, orderType, orderState }: StepperProps) {
	const { t } = useTranslation();
	const isSellOrder = useMemo(() => orderType === 'SELL', [orderType]);
	const stepperItems = useMemo(() => getStepperLabels(t, isMerchant, isSellOrder), [t, isMerchant, isSellOrder]);
	const currentStep = useMemo(() => getStepperValue(orderState), [orderState]);

	useEffect(() => {
		const classes = document.getElementsByClassName('ant-steps-finish-icon');
		for (let i = 0; i < classes.length; i++) {
			classes[i].innerHTML = `<span class="number">${i + 1}</span>`;
		}
	}, [currentStep]);

	return (
		<div className='order-life-cycle-stepper-container'>
			<Steps size='small' current={currentStep} items={stepperItems} />
		</div>
	);
}

export default Stepper;
