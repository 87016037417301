import { ThemeConfig } from 'antd';

export const themeConfig: ThemeConfig = {
	inherit: true,
	components: {
		DatePicker: {
			borderRadius: 2,
			colorBgContainer: '#F5F5F5',
		},
		Input: {
			borderRadius: 2,
			colorBgContainer: '#F5F5F5',
		},
		InputNumber: {
			borderRadius: 2,
			colorBgContainer: '#F5F5F5',
		},
		Select: {
			borderRadius: 2,
			colorBgContainer: '#F5F5F5',
		},
		// Table: {
		// 	cellPaddingBlock: 16,
		// 	cellPaddingInline: 16,
		// 	headerBg: '#F5F5F6',
		// },
	},
};
