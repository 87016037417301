export enum MessageType {
	/**
	 * Text message type used for sending and receiving text data between clients.
	 */
	TEXT = 'TEXT',

	/**
	 * Represents a user joining the chat.
	 */
	JOIN = 'JOIN',

	/**
	 * Represents an action message indicating an action performed on an order,
	 * typically by a client, merchant, or admin.
	 */
	ACTION = 'ACTION',

	/**
	 * File message type used for sending and receiving messages containing file data.
	 */
	FILE = 'FILE',

	/**
	 * Represents a user leaving the chat.
	 */
	LEAVE = 'LEAVE',

	/**
	 * Represents the termination of the chat session.
	 */
	TERMINATED = 'TERMINATED',

	/**
	 * Represents a message sent by a client to acknowledge the receipt of a message.
	 */
	RECEIVED = 'RECEIVED',
}
