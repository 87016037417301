import { TableColumnType, TableColumnsType } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { TFunction } from 'i18next';
import { Wallet } from './types/Wallet';
import { TransactionType } from './components/TransactionType';
import { TransactionStatus } from './components/TransactionStatus';

import dayjs from 'dayjs';

const DATE_TIME_FORMAT = 'DD/MM/YYYY  h:mm A';

type ColumnTitleProps = {
	title: string;
	textColor?: string;
	onHeaderClick?: () => void;
	sortable?: boolean;
	id: string;
	sortBy: string;
	setSortBy: (sortValue: string) => void;
};

const ColumnTitle = (props: ColumnTitleProps) => {
	return (
		<div className='text-[#000D1D99] text-sm flex items-center cursor-pointer'>
			<span className={props?.textColor}>{props.title}</span>
			{props?.sortable && (
				<div className='flex flex-col mx-2'>
					<CaretUpOutlined
						onClick={() => props.setSortBy(props.sortBy === `${props.id}-asc` ? '' : `${props.id}-asc`)}
						style={{ color: props.sortBy === `${props.id}-asc` ? 'black' : 'gray' }}
						className='cursor-pointer'
					/>
					<CaretDownOutlined
						onClick={() => props.setSortBy(props.sortBy === `${props.id}-desc` ? '' : `${props.id}-desc`)}
						style={{ color: props.sortBy === `${props.id}-desc` ? 'black' : 'gray' }}
						className='cursor-pointer'
					/>
				</div>
			)}
		</div>
	);
};

export const columns = ({
	isMobile,
	translate,
	onView,
	sortBy,
	setSortBy,
}: {
	isMobile: boolean;
	translate: TFunction;
	onView: (record: Wallet) => void;
	sortBy: string;
	setSortBy: (sortValue: string) => void;
}): TableColumnsType<Wallet> => [
	{
		key: 'transactionNumber',
		dataIndex: 'transactionNumber',
		width: '20%',
		title: (
			<ColumnTitle title={translate('id')} sortable id='transactionNumber' sortBy={sortBy} setSortBy={setSortBy} />
		),
		render: (value, record, i) => {
			return (
				<div
					id={`wallet_trxno_${i}`}
					className='underline text-cyan-800'
					style={{ cursor: 'pointer' }}
					onClick={() => onView(record)}
				>
					{value}
				</div>
			);
		},
		responsive: ['lg'],
	},
	{
		key: 'type',
		dataIndex: 'type',
		width: '20%',
		title: <ColumnTitle title={translate('type')} sortable id='type' sortBy={sortBy} setSortBy={setSortBy} />,
		render: (value, _record, i) => <TransactionType id={`wallet_type_${i}`} type={value} />,
		responsive: ['lg'],
	},
	{
		key: 'amount',
		dataIndex: 'amount',
		width: '20%',
		title: <ColumnTitle title={translate('amount')} sortable id='amount' sortBy={sortBy} setSortBy={setSortBy} />,
		render: (value, record, i) => <div id={`wallet_amount_${i}`}>{value?.toLocaleString()}</div>,
		responsive: ['lg'],
	},
	{
		key: 'status',
		dataIndex: 'status',
		width: '20%',
		title: <ColumnTitle title={translate('status')} sortable id='state' sortBy={sortBy} setSortBy={setSortBy} />,
		render: (value, record, i) => <TransactionStatus id={`wallet_status_${i}`} status={value} />,
		responsive: ['lg'],
	},
	{
		key: 'createdAt',
		dataIndex: 'createdAt',
		title: (
			<ColumnTitle title={translate('timeCreated')} sortable id='createdAt' sortBy={sortBy} setSortBy={setSortBy} />
		),
		render: (value) => <div>{dayjs(value).format(DATE_TIME_FORMAT)}</div>,
		responsive: ['lg'],
	},
	// --- Mobile ---
	...(isMobile
		? [
				{
					key: 'wallet',
					dataIndex: 'wallet',
					render: (_value, record) => (
						<div>
							<ul className='list-none p-0 m-0 flex flex-col gap-2'>
								<li className='flex items-center justify-between'>
									<span>{translate('id')}</span>
									<span
										className='underline text-cyan-800'
										style={{ cursor: 'pointer' }}
										onClick={() => onView(record)}
									>
										{record?.transactionNumber}
									</span>
								</li>
								<li className='flex items-center justify-between'>
									<span>{translate('type')}</span>
									<TransactionType type={record?.type} />
								</li>
								<li className='flex items-center justify-between'>
									<span>{translate('amount')}</span>
									<span>{record?.amount}</span>
								</li>
								<li className='flex items-center justify-between'>
									<span>{translate('status')}</span>
									<TransactionStatus status={record?.status} />
								</li>
								<li className='flex items-center justify-between'>
									<span>{translate('createdAt')}</span>
									<span>{dayjs(record?.createdAt).format(DATE_TIME_FORMAT)}</span>
								</li>
							</ul>
						</div>
					),
				} as TableColumnType<Wallet>,
		  ]
		: []),
];
