import { useConfigurationContext } from 'store/configurationContext';
import './SubHeader.scss';
import { headerImage as defaultImage } from 'constant/fallback-config';

interface SubHeaderProps {
	title: string;
	description: string;
	subDescription?: string;
}

const SubHeader = ({ title, description, subDescription }: SubHeaderProps) => {
	const { configurationState } = useConfigurationContext();
	const headerImage = configurationState.find((item) => item.configKey === 'header_image')?.value || '';
	return (
		<div className='sub-head py-5' style={{ backgroundImage: `url(${headerImage})` }}>
			<h1 className='text-[32px] text-white font-bold leading-7 mb-3 text-center mt-6'>{title}</h1>
			<p className='text-white text-sm text-center px-4'>{description}</p>
			<p className='text-white text-sm text-center'>{subDescription}</p>
		</div>
	);
};

export default SubHeader;
