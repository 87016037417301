import { CameraFilled, RollbackOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Grid, Modal } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

type ModalProps = {
	open?: boolean;
	setOpen: (open: boolean) => void;
	setCapturedImage: (image: string) => void;
};

const LivePhotoModal = (props: ModalProps) => {
	const { t } = useTranslation();

	const { xs } = Grid.useBreakpoint();
	const videoRef = useRef<HTMLVideoElement | null>(null);
	const canvasRef = useRef<HTMLCanvasElement | null>(null);
	const [capturedImage, setCapturedImage] = useState<string | null>(null);
	const [isCameraReady, setIsCameraReady] = useState(false);

	const startCamera = () => {
		const constraints = {
			video: { facingMode: 'user' }, // Toggle between 'user' (front) and 'environment' (back)
		};

		navigator.mediaDevices
			.getUserMedia(constraints)
			.then((stream) => {
				if (videoRef.current) {
					videoRef.current.srcObject = stream;
					setIsCameraReady(true);
				}
			})
			.catch((err) => {
				console.error('Error accessing camera: ', err);
				alert('Could not access the camera. Please allow camera permissions.');
			});
	};

	useEffect(() => {
		if (props.open) {
			startCamera();
		} else {
			// Stop camera when modal is closed
			stopCamera();
		}

		// Cleanup when the component unmounts
		return () => {
			stopCamera();
		};
	}, [props.open]);

	const stopCamera = () => {
		if (videoRef.current && videoRef.current.srcObject) {
			const stream = videoRef.current.srcObject as MediaStream;
			const tracks = stream.getTracks();
			tracks.forEach((track) => track.stop());
			videoRef.current.srcObject = null; // Clear the video source
		}
	};
	// Capture image from the video feed
	const captureImage = () => {
		if (videoRef.current && canvasRef.current) {
			const video = videoRef.current;
			const canvas = canvasRef.current;
			const context = canvas.getContext('2d');

			if (context) {
				// Match canvas size with video feed
				canvas.width = video.videoWidth;
				canvas.height = video.videoHeight;

				// Draw the current video frame to the canvas
				context.drawImage(video, 0, 0, canvas.width, canvas.height);
				const imageData = canvas.toDataURL('image/png');
				setCapturedImage(imageData);

				// Stop video stream after capturing (optional)
				const stream = video.srcObject as MediaStream;
				const tracks = stream.getTracks();
				tracks.forEach((track) => track.stop());
			}
		}
	};

	return (
		<Modal
			open={props?.open}
			footer={null}
			closable
			onCancel={() => {
				props.setOpen(false);
				stopCamera(); // Stop camera when modal is closed
			}}
			width={570}
		>
			<div className='flex flex-col gap-4 relative' style={{ minHeight: xs ? '300px' : '450px' }}>
				<p className='text-xl font-bold m-0'> {t('Take Photo')}</p>
				{/* Live video feed */}
				{!capturedImage && (
					<video
						ref={videoRef}
						autoPlay
						playsInline
						width='100%' // Responsive width
					/>
				)}

				{/* Canvas for capturing the image */}
				<canvas ref={canvasRef} style={{ display: 'none' }} />

				{/* Display the captured image */}
				{capturedImage && <img src={capturedImage} alt='Captured' style={{ width: '100%', maxHeight: '400px' }} />}

				{/* Capture Image Button */}
			</div>
			{!capturedImage && (
				<div className='w-full bg-[#f3f3f3] mt-4 p-3 flex justify-center'>
					<Button
						id='live-photo-dialog_capture-img'
						disabled={!isCameraReady}
						onClick={() => {
							captureImage();
						}}
						className='h-10 w-10 mr-2 border-none flex items-center justify-center rounded-full bg-[#008bff]'
						htmlType='button'
					>
						<CameraFilled className='text-white text-[20px]' />
					</Button>
				</div>
			)}
			{capturedImage && (
				<div className='w-full flex justify-between p-2'>
					<Button
						id='live-photo-dialog_retake-button'
						onClick={() => {
							startCamera();
							setCapturedImage('');
						}}
						className=' mr-2 border-none flex items-center justify-center rounded bg-[#008bff] text-white hover:!text-white'
						htmlType='button'
					>
						<RollbackOutlined className='text-[20px]' />
						Retake
					</Button>
					<Button
						id='live-photo-dialog_upload-img'
						onClick={() => {
							props.setCapturedImage(capturedImage);
							props.setOpen(false);
							stopCamera(); // Ensure camera stops after upload
						}}
						className=' mr-2 border-none flex items-center justify-center rounded bg-[#008bff] text-white hover:!text-white'
						htmlType='button'
					>
						Upload
						<UploadOutlined className='text-[20px]' />
					</Button>
				</div>
			)}
		</Modal>
	);
};

export default LivePhotoModal;
