import { initialTheme } from '../assets/config/initial-theme';

export function getProjectTheme() {
	return {
		token: {
			colorPrimary: initialTheme.colorPrimary,
			colorPrimaryHover: initialTheme.colorPrimaryHover,
			colorError: initialTheme.colorError,
			colorSuccess: initialTheme.colorSuccess,
			colorInfo: initialTheme.colorInfo,
			colorTextBase: initialTheme.colorTextBase,
			colorBgLayout: initialTheme.colorBgLayout,
		},
		components: {
			Menu: {
				colorItemBg: initialTheme.colorBgMenu,
				colorSubItemBg: initialTheme.colorBgMenu,
			},
			Table: {
				cellPaddingBlock: 0,
				cellPaddingInline: 5,
				headerBorderRadius: 0,
				headerBg: '#F5F5F6',
			},
		},
	};
}
