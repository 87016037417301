import { Outlet, useLocation } from 'react-router';
import React, { useEffect } from 'react';
import axios from 'axios';
import { Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useMainContext } from '../store/MainContext';
import { API_ENDPOINTS } from '../assets/api/endpoints';
import { CountryItem } from '../models/Country';
import { getCountryByCountryCode } from '../helpers/countriesMap';
import { useTranslation } from 'react-i18next';
import './ProtectedRoutes.scss';
import { useCountriesContext } from '../store/CountriesContext';
import { getUserInfo } from 'helpers/localStorageHandler';

import { useNavigate } from 'react-router-dom';

function ProtectedRoutes() {
	const { t } = useTranslation();
	const { mainState } = useMainContext();
	const { countriesState, setCountriesState } = useCountriesContext();
	const location = useLocation();
	const token = getUserInfo()?.token;

	useEffect(() => {
		// Function to remove all recaptcha scripts
		const recaptcha = document.querySelector('.grecaptcha-badge') as HTMLElement | null;
		if (recaptcha) recaptcha.style.display = 'none';
	}, [location]);

	useEffect(() => {
		const countryCode = mainState.countryCode ?? '';

		if (mainState.isLoggedIn) {
			axios
				.get(API_ENDPOINTS.countries, { headers: { Authorization: `Bearer ${token}` } })
				.then((res) => {
					const countries = res.data
						?.filter((country: CountryItem) => country.isPublic)
						?.map((country: CountryItem) => ({
							...country,
							methods: country?.methods.filter((method) => method?.isPublic),
						}));
					const paymentMethods = countries.map((country: CountryItem) => country.methods)?.flat();

					setCountriesState({
						...countriesState,
						countries,
						paymentMethods,
						allPaymentMethod: res.data.map((country: CountryItem) => country.methods)?.flat(),
						myCountry: getCountryByCountryCode(countryCode, t),
					});
				})
				.catch((error) => console.error(error));
		}
	}, []);

	return (
		<main className='main'>
			<Layout>
				<Layout className='site-layout'>
					<Content>
						<Outlet />
					</Content>
				</Layout>
			</Layout>
		</main>
	);
}

export default ProtectedRoutes;
