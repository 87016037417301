import { CreditCardTwoTone, DollarTwoTone } from '@ant-design/icons';
import { Button, Modal, Form, Select, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { WalletActionType } from '../types/walletActionType';
import { useEffect, useState } from 'react';
import { API_ENDPOINTS } from 'assets/api/endpoints';
import { getUserInfo } from 'helpers/localStorageHandler';
import axios from 'axios';
import { MyAccount } from 'models/MyAccounts';
import { WalletAccount } from '../types/WalletAccount';
import { OTPTransactionModal } from './OTPtransactionModal';
import UploadLivePicture from '../components/UploadLivePicture';
import { base64ToFile } from 'helpers/base64ToFile';
import { useConfigurationContext } from 'store/configurationContext';
import CaptchaButton from 'components/Button/CaptchaButton';

type WithdrawDepositModalProps = {
	open?: boolean;
	setOpen: (open: boolean) => void;
	type: WalletActionType | string;
};

export const WithdrawDepositModal = (props: WithdrawDepositModalProps) => {
	const [account, setAccount] = useState<MyAccount>();
	const [accounts, setAccounts] = useState<MyAccount[]>([]);
	const [myWallets, setMyWallets] = useState<WalletAccount>();
	const [amount, setAmount] = useState('');
	const [amountError, setAmountError] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [capturedImage, setCapturedImage] = useState<string | null>(null);
	const [recaptcha, setRecaptcha] = useState('');
	const [isEnabled, setIsEnabled] = useState(false);
	const [version, setRecapchaV] = useState('');

	const { t } = useTranslation();
	const token = getUserInfo()?.token;
	const { configurationState } = useConfigurationContext();

	useEffect(() => {
		Promise.all([
			axios.get(API_ENDPOINTS.accounts, { headers: { Authorization: `Bearer ${token}` } }),
			axios.get(API_ENDPOINTS.myWallets, { headers: { Authorization: `Bearer ${token}` } }),
		])
			.then(([accounts, wallets]) => [accounts?.data, wallets?.data])
			.then(([accounts, wallets]) => {
				setAccounts(accounts);
				setMyWallets(wallets[0]);
			});
	}, [t, token]);

	useEffect(() => {
		if (props.type === 'WITHDRAW') {
			if (Number(amount) > Number(account?.balance)) {
				setAmountError(
					t<string>('amountmustbelessThanavailabel', { amount: account?.balance, currency: account?.currency }),
				);
			} else if (Number(amount) < Number(account?.sellMin) || Number(amount) > Number(account?.sellMax)) {
				setAmountError(
					t<string>('amountshouldliebetween', {
						min: account?.sellMin,
						max: account?.sellMax,
						currency: account?.currency,
					}),
				);
			} else {
				setAmountError('');
			}
		} else {
			if (Number(amount) > Number(myWallets?.balance)) {
				setAmountError(
					t<string>('amountmustbelessThanavailabel', { amount: myWallets?.balance, currency: myWallets?.currency }),
				);
			} else if (Number(amount) < Number(account?.buyMin) || Number(amount) > Number(account?.buyMax)) {
				setAmountError(
					t<string>('amountshouldliebetween', {
						min: account?.buyMin,
						max: account?.buyMax,
						currency: myWallets?.currency,
					}),
				);
			} else {
				setAmountError('');
			}
		}
	}, [amount, account]);

	const onRequest = () => {
		const formData = new FormData();

		// Define the payload
		const payload: any = {
			type: props.type,
			quantity: Number(amount),
			tradingPlatform: account?.tradingPlatform,
			tradingAccount: account?.number,
			currency: props.type === WalletActionType.WITHDRAW ? account?.currency : myWallets?.currency,
		};

		// Append payload fields to FormData
		for (const key in payload) {
			formData.append(key, payload[key]);
		}

		// Append the image file to FormData
		if (capturedImage) {
			const imageFile = base64ToFile(capturedImage, 'image.png');
			formData.append('file', imageFile);
		}
		setIsLoading(true);
		const headers: Record<string, string> = {
			Authorization: `Bearer ${token}`,
		};

		if (isEnabled && recaptcha) {
			headers['RECAPTCHA-TOKEN'] = recaptcha;
		}
		axios
			.post(API_ENDPOINTS.walletTransaction, formData, {
				headers: headers,
			})
			.then(() => props.setOpen(false))
			.catch(console.error)
			.finally(() => setIsLoading(false));
	};

	const withdraw_required_live_picture =
		configurationState.find((item) => item.configKey === 'withdraw_required_live_picture')?.value || false;
	return (
		<>
			<Modal open={props?.open} footer={null} onCancel={() => props.setOpen(false)} destroyOnClose={true} width={570}>
				<div className='flex flex-col gap-2'>
					<div className='mx-auto mt-6'>
						<div className='rounded-full w-16 h-16 bg-[#eee] flex items-center justify-center'>
							<CreditCardTwoTone className='text-3xl' />
						</div>
					</div>
					<p className='font-bold text-base text-center m-0 p-0'>
						{props.type === 'WITHDRAW' ? t('withdrawRequesttitle') : t('deposit')}
					</p>
					<p className='text-sm text-center text-gray m-0 p-0'>
						{props.type === 'WITHDRAW' ? t('withdrawRequestSubtitle') : t('depositRequestSubtitle')}
					</p>
					<div>
						<p className='font-bold my-2 text-[14px]'>{t('account')}</p>
						<Form.Item name='account' className='min-w-64 my-1 rounded'>
							<Select
								value={account?.number}
								onChange={(e) => setAccount(accounts.find((acc) => acc.number === e))}
								options={accounts.map((account) => ({
									label: `${account.accountGroup} | ${account.number} | ${account.tradingPlatform} | ${account.balance} ${account.currency}`,
									value: account.number,
								}))}
								className='w-full'
								size='large'
							/>
						</Form.Item>
						{account && (
							<p className='text-[10px] m-0'>
								{props.type === 'WITHDRAW' ? (
									<>
										{t('accountLimit')}:
										<span className='font-bold mx-1 '>
											{account.sellMin} ~ {account.sellMax} {account.currency}
										</span>
										{t('credit')}:
										<span className='font-bold mx-1 '>
											{account.credit} {myWallets?.currency}
										</span>
										{account.withdrawRatio && (
											<>
												{t('commisionPercentage')}:{' '}
												<span className='font-bold '>{account.withdrawCommission * 100}%</span>
											</>
										)}
									</>
								) : (
									<>
										{t('accountLimit')}:
										<span className='font-bold mx-1 '>
											{account.buyMin} ~ {account.buyMax} {myWallets?.currency}
										</span>
										{t('credit')}:
										<span className='font-bold mx-1 '>
											{account.credit} {myWallets?.currency}
										</span>
										{account.depositRatio && (
											<>
												{t('bounsPercentage')}: <span className='font-bold '>{account.depositBonus * 100}%</span>
											</>
										)}
									</>
								)}
							</p>
						)}
						<p className='font-bold my-2 text-[14px] mt-4'>
							{props.type === 'WITHDRAW' ? t('withdrawAmount') : t('depositAmount')}
						</p>
						<Form.Item name='amount' className='min-w-64 my-1 rounded'>
							<Input
								value={amount}
								onChange={(e) => setAmount(e.target.value)}
								type='number'
								min={0}
								step={'any'}
								suffix={
									<>
										<DollarTwoTone className='mx-1 text-[18px]' /> USD{' '}
									</>
								}
								className='w-full p-2'
							/>
						</Form.Item>
						{account && amount && (
							<p className='text-[12px] m-0'>
								{' '}
								{props.type === 'WITHDRAW' ? (
									account.withdrawCommission ? (
										<>
											{t('commisionAmount')}:{' '}
											<span className='font-bold '>
												{account.withdrawRatio
													? Number(amount) * account.withdrawCommission > account.credit
														? account.credit
														: (Number(amount) * account.withdrawCommission).toFixed(3)
													: Number(account.withdrawCommission)}{' '}
												{account.currency}
											</span>{' '}
										</>
									) : null
								) : account.depositBonus ? (
									<>
										{t('bounsAmount')}:{' '}
										<span className='font-bold '>
											{account.depositRatio ? (Number(amount) * account.depositBonus).toFixed(3) : account.depositBonus}{' '}
											{myWallets?.currency}
										</span>{' '}
									</>
								) : null}
							</p>
						)}
						{amountError && amount && <div className='text-[12px] text-red-500'>{amountError}</div>}
					</div>
					{props.type === 'WITHDRAW' && withdraw_required_live_picture === 'true' ? (
						<UploadLivePicture setCapturedImage={setCapturedImage} />
					) : null}
					<Button
						disabled={
							!account ||
							!amount ||
							!!amountError ||
							(props.type === 'WITHDRAW' && !capturedImage && withdraw_required_live_picture === 'true') ||
							(version === 'v2' && isEnabled && !recaptcha)
						}
						loading={isLoading}
						onClick={onRequest}
						className='w-full border-none shadow-none text-white mt-2 mb-8'
						style={{
							background:
								account &&
								amount &&
								!amountError &&
								!(props.type === 'WITHDRAW' && withdraw_required_live_picture === 'true' && !capturedImage)
									? 'linear-gradient(#00AFFF, #0077FF)'
									: 'lightGray',
							color: 'white',
						}}
						htmlType='button'
						size='large'
						type='primary'
					>
						{props.type === 'WITHDRAW' ? t('withdrawRequest') : t('deposit')}
					</Button>
					<div className='w-full flex justify-center'>
						<CaptchaButton
							setRecaptchaToken={setRecaptcha}
							setIsRecaptchaEnabled={setIsEnabled}
							action='WALLET_TRADING_OPERATION'
							setRecapchaV={setRecapchaV}
						/>
					</div>
				</div>
			</Modal>
		</>
	);
};
