export enum OfferType {
	sell = 'sell',
	buy = 'buy',
}

export enum MyOrdersFilters {
	priceDesc = 'price-desc',
	priceAsc = 'price-asc',
	quantityAsc = 'quantity-asc',
	quantityDesc = 'quantity-desc',
	amountAsc = 'amount-asc',
	amountDesc = 'amount-desc',
	merchantsAsc = 'merchants-asc',
	merchantsDesc = 'merchants-desc',
	clientAsc = 'client-asc',
	clientDesc = 'client-desc',
	statusAsc = 'status-asc',
	statusDesc = 'status-desc',
	dateAndTimeAsc = 'createdAt-asc',
	dateAndTimeDesc = 'createdAt-desc',
	typeAsc = 'type-asc',
	typeDesc = 'type-desc',
}

export type MyOrder = {
	orderNumber: string;
	sent: number;
	receive: number;
	price: number;
	status: string;
	createdAt: string;
	exchangeBaseCurrency: string;
	merchantFullName?: string;
	merchantId?: string;
	clientFullName?: string;
	amount: string;
	quantity: string;
	type: string;
};
