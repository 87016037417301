import { MyOffers } from '../models/MyOffers';

export function isMySellOfferFormInValid(offer: MyOffers) {
	return (
		!offer.currency ||
		!offer.max ||
		!offer.methods?.length ||
		!offer.min ||
		Number(offer.min) >= Number(offer.max) ||
		offer?.profitRatio === undefined ||
		offer?.profitRatio === null ||
		!offer.available
	);
}

export function isMyBuyOfferFormInValid(offer: MyOffers) {
	return (
		!offer.currency ||
		!offer.max ||
		!offer.methods?.length ||
		!offer.min ||
		Number(offer.min) >= Number(offer.max) ||
		offer?.profitRatio === undefined ||
		offer?.profitRatio === null
	);
}
