import CryptoJS from 'crypto-js';
import * as Base64 from 'base-64';

const secretKey: string = process.env.REACT_APP_ENCODED_KEY || '';

function encodeKey(str: string) {
	const encoded: string = Base64.encode(str);
	return encoded;
}
function processString(input: string): string {
	const length = input.length;
	// If the input string is less than 32 characters, append "=" signs
	if (length < 32) {
		let processedString = input;
		while (processedString.length < 32) {
			processedString += '=';
		}

		return processedString;
	}
	// If the input string is more than 32 characters, truncate it to 32 characters
	else if (length > 32) {
		return input.substring(0, 32);
	}
	// If the input string is exactly 32 characters, return it as is
	else {
		return input;
	}
}
function generateKey(encodedKey: string) {
	const parsedBase64Key = CryptoJS.enc.Base64.parse(encodedKey);
	return parsedBase64Key;
}
export const encryptData = (plainText: string, key: string) => {
	const processedString = processString(key);
	const parsedBase64Key = key == null ? generateKey(secretKey) : generateKey(encodeKey(processedString));
	const encryptedData = CryptoJS.AES.encrypt(plainText, parsedBase64Key, {
		mode: CryptoJS.mode.ECB,
		padding: CryptoJS.pad.Pkcs7,
	});

	const encryptedCipherText = encryptedData.toString();
	return encryptedCipherText;
};
