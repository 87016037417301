export enum OfferType {
	sell = 'sell',
	buy = 'buy',
}

export enum MerchantFilters {
	bestSellers = 'rating-desc',
	newSellers = 'new-sellers',
	maxOrders = 'completedOrders-desc',
	minOrders = 'completedOrders-asc',
	priceDesc = 'price-desc',
	priceAsc = 'price-asc',
	availableDesc = 'available-desc',
	availableAsc = 'available-asc',
}

export type Merchant = {
	merchantId: number;
	fullName: string;
	rating: number;
	totalReviews: number;
	ordersCompleted: number;
	rate: number;
	available: number;
	onHold: number;
	min: number;
	max: number;
	country: { name: string; code: string };
	countryCode: string;
	currencyCode: string;
	currency: string;
	paymentMethods: number[];
	termsNote: string;
	exchangeBaseCurrency: string;
	limitReached: boolean;
	isCoolDown: boolean;
	online: boolean;
	totalOrders: number;
};
