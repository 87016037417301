import TextArea from 'antd/es/input/TextArea';
import './FeedbackForm.scss';
import { Button, Form, Switch, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { CheckCircleOutlined, LikeFilled, DislikeFilled, LikeOutlined, DislikeOutlined } from '@ant-design/icons';
import axios from 'axios';
import { API_ENDPOINTS } from '../../../../../../../assets/api/endpoints';
import { getUserInfo } from '../../../../../../../helpers/localStorageHandler';
import { getUserLanguageInCookie } from 'helpers/cookiesHandler';

function FeedbackForm({ orderNumber, getOrderDetails }: { orderNumber: string; getOrderDetails: () => void }) {
	const { t } = useTranslation();
	const [feedbackForm] = Form.useForm();
	const [isFeedbackFormValid, setIsFeedbackFormValid] = useState(false);
	const [isMerchantGood, setIsMerchantGood] = useState<boolean | null>(null);
	const [hoveredLike, setHoveredLike] = useState(false); // State to track like button hover
	const [hoveredDislike, setHoveredDislike] = useState(false);
	const token = getUserInfo()?.token;
	const handleFeedbackFormSubmit = () => {
		const feedback = feedbackForm.getFieldsValue();
		axios
			.post(
				API_ENDPOINTS.review,
				{
					orderNumber: Number(orderNumber),
					isLike: isMerchantGood,
					comment: feedback.comment,
				},
				{ headers: { Authorization: `Bearer ${token}` } },
			)
			.then(() => {
				getOrderDetails();
				notification['success']({
					message: '',
					description: <div className='text-[green] font-bold ps-[15px]'>{t<string>('reviewSubmitted')}</div>,
					style: { backgroundColor: '#d8e4dd', height: '75px', display: 'flex', alignItems: 'center' },
					icon: <CheckCircleOutlined className='w-[52px] h-[52px] text-[#33A867]' />,
				});
			})
			.catch((error) => console.error(error));
	};

	return (
		<Form
			name='basic'
			className='feedback-form'
			form={feedbackForm}
			layout='vertical'
			autoComplete='off'
			onValuesChange={() => setIsFeedbackFormValid(feedbackForm.getFieldValue('comment'))}
			dir={getUserLanguageInCookie() === 'ar' ? 'rtl' : 'ltr'}
		>
			<Form.Item name='isMerchantGood' initialValue={isMerchantGood} className='feedBack-icon'>
				{isMerchantGood === true || hoveredLike ? (
					<LikeFilled
						onClick={() => setIsMerchantGood(true)}
						onMouseEnter={() => setHoveredLike(true)}
						onMouseLeave={() => setHoveredLike(false)}
						className='mx-1 cursor-pointer text-[20px] text-[#33A867]'
					/>
				) : (
					<LikeOutlined
						onClick={() => setIsMerchantGood(true)}
						onMouseEnter={() => setHoveredLike(true)}
						onMouseLeave={() => setHoveredLike(false)}
						className='mx-1 cursor-pointer text-[20px] text-[#33A867]'
					/>
				)}
				{isMerchantGood === false || hoveredDislike ? (
					<DislikeFilled
						onClick={() => setIsMerchantGood(false)}
						onMouseEnter={() => setHoveredDislike(true)}
						onMouseLeave={() => setHoveredDislike(false)}
						className='mx-1 cursor-pointer text-[20px] text-[#F6465D]'
					/>
				) : (
					<DislikeOutlined
						onClick={() => setIsMerchantGood(false)}
						onMouseEnter={() => setHoveredDislike(true)}
						onMouseLeave={() => setHoveredDislike(false)}
						className='mx-1 cursor-pointer text-[20px] text-[#F6465D]'
					/>
				)}
			</Form.Item>
			<Form.Item
				label={t<string>('rateTheMerchant')}
				name='comment'
				rules={[{ required: true, message: t<string>('reviewIsRequired') }]}
			>
				<TextArea rows={4} placeholder={t<string>('writeReviewAboutTheMerchant')} />
			</Form.Item>
			<Form.Item style={{ margin: 0 }}>
				<Button
					className='form-btn'
					style={{ margin: '0 auto', maxWidth: '100px', height: '36px', fontWeight: 400 }}
					type='primary'
					htmlType='submit'
					disabled={!isFeedbackFormValid || isMerchantGood === null}
					onClick={() => handleFeedbackFormSubmit()}
				>
					{t<string>('send')}
				</Button>
			</Form.Item>
		</Form>
	);
}

export default FeedbackForm;
