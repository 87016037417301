import React, { createContext, useContext, useState, ReactNode } from 'react';

interface NotificationContextProps {
	children: ReactNode;
}

interface NotificationContextValue {
	updateNotificationCount: () => void;
	notificationCount: number;
}

const NotificationContext = createContext<NotificationContextValue | undefined>(undefined);

export const NotificationProvider: React.FC<NotificationContextProps> = ({ children }) => {
	const [notificationCount, setNotificationCount] = useState<number>(0);

	const updateNotificationCount = () => {
		setNotificationCount((prevCount) => prevCount + 1);
	};

	return (
		<NotificationContext.Provider value={{ updateNotificationCount, notificationCount }}>
			{children}
		</NotificationContext.Provider>
	);
};

export const useNotifications = (): NotificationContextValue => {
	const context = useContext(NotificationContext);
	if (!context) {
		throw new Error('useNotification must be used within a NotificationProvider');
	}
	return context;
};
