import { Button, Collapse, Dropdown, Form, Grid, Input, MenuProps, Select, Table } from 'antd';

import { useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Merchant, MerchantFilters, OfferType } from '../../models/Merchants';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { getMerchantsColumns } from '../../helpers/merchantsTableColumns';
import Sell from '../../assets/images/icons/Sell';
import Buy from '../../assets/images/icons/Buy';
import MerchantBuyAndSellModal from '../../components/MerchantBuyAndSellModal/MerchantBuyAndSellModal';
import { getSortByValue } from '../../helpers/getMerchantSortByValue';
import { useMainContext } from '../../store/MainContext';
import { getUserInfo } from '../../helpers/localStorageHandler';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Merchants.scss';
import SubHeader from '../../components/SubHeader/SubHeader';
import { useCountriesContext } from '../../store/CountriesContext';
import { CaretDownOutlined, SyncOutlined, SearchOutlined } from '@ant-design/icons';
import { LimitCheckModal } from 'components/MerchantBuyAndSellModal/LimitCheckModal';
import { Wallet } from 'models/Wallet';
import { StyledPagination } from 'components/table.style';
import { useConfigurationContext } from 'store/configurationContext';

const Merchants = () => {
	const { t } = useTranslation();
	const { lg } = Grid.useBreakpoint();
	const navigate = useNavigate();
	const { mainState, setMainState } = useMainContext();
	const { countriesState } = useCountriesContext();
	const [offerType, setOfferType] = useState<OfferType.buy | OfferType.sell>(OfferType.buy);
	const [merchantsLoading, setMerchantsLoading] = useState(false);
	const [merchants, setMerchants] = useState([]);
	const [searchTerm, setSearchTerm] = useState('');
	const [pageSize, setPageSize] = useState(10);
	const [sortBy, setSortBy] = useState('activeBuyer-desc');
	const [sortOrder, setSortOrder] = useState('price-asc');
	const [paymentMethodFilter, setPaymentMethodFilter] = useState(t<string>('all'));
	const [countryFilter, setCountryFilter] = useState(getUserInfo()?.countryCode);
	const [minLimitFilter, setMinLimitFilter] = useState('');
	const [maxLimitFilter, setMaxLimitFilter] = useState('');
	const [showModal, setShowModal] = useState(false);
	const [merchantID, setMerchantID] = useState<number | null>(null);
	const [wallet, setWallet] = useState<Wallet | null>(null);
	const [urlForOffers, setUrlForOffers] = useState('');
	const [totalElements, setTotalElements] = useState(0);
	const [page, setPage] = useState(0);
	const [searchValue, setSearchValue] = useState('');
	const [refresh, setRefersh] = useState(false);
	const [merchant, setMerchant] = useState();
	const [isLimitReached, setIsLimitReached] = useState<boolean>(false);
	const [isLimitReachedModal, setIsLimitReachedModal] = useState<boolean>(false);
	const [activeKey, setActiveKey] = useState<string[] | string>(lg ? ['1'] : []);
	const [paymentMethods, setPaymentMethods] = useState([
		{
			methodId: 0,
			countryCode: '',
			methodName: t<string>('all'),
			color: '#000000',
			bgColor: 'transparent',
			isPublic: true,
		},
		...countriesState.paymentMethods,
	]);
	useEffect(() => {
		setPaymentMethodFilter(t<string>('all'));
	}, [t]);

	const token = getUserInfo()?.token;
	const isMerchant = useMemo(() => getUserInfo()?.isMerchant ?? false, []);
	const userStatus = getUserInfo()?.userStatus ?? '';
	const isDev = window.location.hostname.includes('devb');
	const { configurationState } = useConfigurationContext();

	useEffect(() => {
		setMerchantsLoading(true);

		if (!mainState.isLoggedIn && !Boolean(token)) {
			setMainState({ ...mainState, isLoggedIn: false });
			navigate('/sign-in');
		} else {
			let paymentMethod =
				paymentMethodFilter !== t<string>('all') && paymentMethodFilter !== ''
					? `&paymentMethodKey=${paymentMethodFilter}`
					: '';
			let country = countryFilter !== t<string>('all') && countryFilter !== '' ? `&countryCode=${countryFilter}` : ``;
			let minimum = minLimitFilter ? `&minimumLimit=${minLimitFilter}` : '';
			let maximum = maxLimitFilter ? `&maximumLimit=${maxLimitFilter}` : '';
			let search = searchTerm ? `&wildSearch=${searchTerm}` : '';
			let sort = '';
			if (sortBy) {
				sort = `${sortBy}%2C`;
			}
			if (sortOrder.startsWith('price')) {
				if (offerType === OfferType.buy) {
					sort += sortOrder.replace('price', 'buyRate');
				} else {
					sort +=
						sortOrder.split('-')[1] === 'asc'
							? sortOrder.replace('price-asc', 'sellRate-desc')
							: sortOrder.replace('price-desc', 'sellRate-asc');
				}
			} else {
				sort += sortOrder;
			}
			const offerTypeValue = offerType === OfferType.buy ? 'BUY' : 'SELL';
			const UrlForOffers = `${
				API_ENDPOINTS.offers
			}${offerTypeValue}?page=${page}&pageSize=${pageSize}${paymentMethod}${country}${minimum}${maximum}${search}${
				sort ? `&sort=${sort}` : ''
			}`;
			setUrlForOffers(UrlForOffers);

			axios
				.get(UrlForOffers, { headers: { Authorization: `Bearer ${token}` } })
				.then((result) => {
					setMerchants(result.data.data);
					setTotalElements(result.data.totalElements);
					setMerchantsLoading(false);
				})
				.catch((error) => {
					console.error(error);
					setMerchantsLoading(false);
				});
		}
	}, [
		countryFilter,
		maxLimitFilter,
		minLimitFilter,
		pageSize,
		paymentMethodFilter,
		searchValue,
		sortBy,
		page,
		sortOrder,
		isLimitReached,
	]);

	useEffect(() => {
		setPage(0);
		setPageSize(10);
	}, [countryFilter, maxLimitFilter, minLimitFilter, offerType, paymentMethodFilter, searchValue]);

	// Fetch default wallet details.
	useEffect(() => {
		if (showModal) {
			axios
				.get(API_ENDPOINTS.wallet, { headers: { Authorization: `Bearer ${token}` } })
				.then((response) => {
					setWallet(response?.data);
				})
				.catch(console.error);
		}
	}, [showModal]);

	const onOfferTypeBtnClick = (offerType: OfferType) => setOfferType(offerType);

	const refreshData = () => {
		axios
			.get(urlForOffers, { headers: { Authorization: `Bearer ${token}` } })
			.then((result) => {
				setMerchants(result.data.data);
				setMerchantsLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setMerchantsLoading(false);
			});
	};

	const changeOageSize = (pageSize: number) => {
		setPage(0);
		setPageSize(pageSize);
	};

	const pageSizeItems: MenuProps['items'] = [
		{ label: '10', key: '1', onClick: () => changeOageSize(10) },
		{ label: '20', key: '2', onClick: () => changeOageSize(20) },
		{ label: '50', key: '3', onClick: () => changeOageSize(50) },
		{ label: '100', key: '4', onClick: () => changeOageSize(100) },
	];

	const sortByItems: MenuProps['items'] = useMemo(() => {
		if (countryFilter !== t<string>('all') && countryFilter !== '') {
			return [
				{
					label: t<string>('bestSellers'),
					key: '1',
					onClick: () => setSortOrder(MerchantFilters.bestSellers),
				},
				{
					label: t<string>('maxOrders'),
					key: '2',
					onClick: () => setSortOrder(MerchantFilters.maxOrders),
				},
				{
					label: t<string>('priceHight'),
					key: '3',
					onClick: () => setSortOrder(MerchantFilters.priceDesc),
				},
				{
					label: t<string>('priceLow'),
					key: '4',
					onClick: () => setSortOrder(MerchantFilters.priceAsc),
				},
				{
					label: t<string>('minOrders'),
					key: '5',
					onClick: () => setSortOrder(MerchantFilters.minOrders),
				},
			];
		} else {
			return [
				{
					label: t<string>('bestSellers'),
					key: '1',
					onClick: () => setSortOrder(MerchantFilters.bestSellers),
				},
				{
					label: t<string>('maxOrders'),
					key: '2',
					onClick: () => setSortOrder(MerchantFilters.maxOrders),
				},
				{
					label: t<string>('minOrders'),
					key: '3',
					onClick: () => setSortOrder(MerchantFilters.minOrders),
				},
			];
		}
	}, [countryFilter, t]);

	const clearFilters = () => {
		setPaymentMethodFilter(t<string>('all'));
		setCountryFilter(getUserInfo()?.countryCode);
		setMinLimitFilter('');
		setMaxLimitFilter('');
		setSearchTerm('');
	};

	useEffect(() => {
		setPage(0);
		setSortBy(offerType === OfferType.buy ? 'activeBuyer-desc' : 'activeSeller-desc');
	}, [offerType]);

	useEffect(() => {
		if (merchantID && showModal) {
			axios
				.get(API_ENDPOINTS.getLimitReached.replace('%merchantId%', `${merchantID}`), {
					headers: { Authorization: `Bearer ${token}` },
				})
				.then((result) => setIsLimitReached(result.data.limitReached))
				.catch((error) => console.error(error));
		}
	}, [merchantID, showModal]);

	useEffect(() => {
		const delaySearch = setTimeout(() => {
			setSearchValue(searchTerm);
		}, 1000);
		return () => clearTimeout(delaySearch);
	}, [searchTerm]);

	useEffect(() => {
		let merchant = merchants.find((merchant: Merchant) => merchant.merchantId === merchantID);
		setMerchant(merchant);
	}, [merchantID, merchants]);
	useEffect(() => {
		const paymentMethods = countriesState.paymentMethods.filter((item) => item.countryCode === countryFilter);
		const methods =
			paymentMethods.length > 0
				? paymentMethods
				: [
						{
							methodId: 0,
							countryCode: '',
							methodName: t<string>('all'),
							color: '#000000',
							bgColor: 'transparent',
							isPublic: true,
						},
						...countriesState.paymentMethods,
				  ];
		setPaymentMethods(methods);
	}, [countryFilter, countriesState.paymentMethods, t]);

	useEffect(() => {
		if (countryFilter) {
			if (offerType === OfferType.buy) {
				setSortOrder(MerchantFilters.priceAsc);
			}
			if (offerType === OfferType.sell) {
				setSortOrder(MerchantFilters.priceDesc);
			}
		} else {
			setSortOrder('');
		}
	}, [countryFilter, offerType]);

	useEffect(() => {
		// Open panel by default if large screen (`lg`) is active, otherwise close
		if (lg) {
			setActiveKey(['1']);
		} else {
			setActiveKey([]);
		}
	}, [lg]);

	const themeColor = configurationState.find((item) => item.configKey === 'theme_color')?.value || '';
	const secondary_color = configurationState.find((item) => item.configKey === 'secondary_color')?.value || '';
	return (
		<div className='merchants-container'>
			<SubHeader title={t<string>('merchantsOffer')} description={t<string>('merchantsOfferSubtitle')} />
			<div className='min-h-[72vh] max-w-[1440px] mx-auto px-[10px] lg:px-[75px]  xss:pt-[20px] sm:pt-[30px] pb-10 bg-white'>
				<div className='flex items-center justify-between mb-3 btn-container'>
					<div className='flex bg-[#F5F5F5] rounded-[4px] px-[3px]'>
						<Button
							id='merchants_deposit_button'
							onClick={() => {
								onOfferTypeBtnClick(OfferType.buy);
								clearFilters();
							}}
							className={`flex items-center justify-center  h-[34px] border-none text-sm w-auto ${
								offerType === OfferType.buy
									? 'text-white bg-green-500 hover:!text-white'
									: 'bg-transparent text-black hover:!text-black'
							}`}
						>
							<span className='mx-[6px] inline-block'>
								<Buy active={offerType === OfferType.buy} />
							</span>
							{isDev ? t<string>('buy') : t<string>('deposit')}
						</Button>
						<Button
							id='merchants_withdrawal_button'
							onClick={() => {
								onOfferTypeBtnClick(OfferType.sell);
								clearFilters();
							}}
							className={`flex items-center justify-center  h-[34px] border-none text-sm w-auto ${
								offerType === OfferType.sell
									? 'text-white bg-red-500 hover:!text-white'
									: 'bg-transparent text-black hover:!text-black'
							}`}
						>
							<span className='mx-[6px] inline-block'>
								<Sell active={offerType === OfferType.sell} />
							</span>
							{isDev ? t<string>('sell') : t<string>('withdrawal')}
						</Button>
					</div>
				</div>
				<Collapse activeKey={activeKey} onChange={(key) => setActiveKey(key)}>
					<Collapse.Panel header={t('Filter Options')} key='1'>
						<div className='border-[#000d1d]/10 rounded-md shadow-md py-3  px-4  border border-solid border-gray-200 flex flex-col md:flex-row md:flex-wrap'>
							<Form layout='vertical'>
								<div className='field-container'>
									<div className='flex flex-col md:flex-row gap-3'>
										<Form.Item label={t<string>('countries')} className='xss:w-full md:w-[300px] my-0'>
											<Select
												defaultValue={'All'}
												value={countryFilter}
												onChange={(e: string) => setCountryFilter(e)}
												showSearch
												filterOption={(inputValue, option: any) =>
													option?.children ? option.children[1].toLowerCase().includes(inputValue.toLowerCase()) : false
												}
											>
												{[
													{
														countryCode: '',
														currency: '',
														country: 'All',
														isPublic: true,
														methods: [],
														countryName: t<string>('all'),
													},
													...countriesState.countries,
												].map((item, index) => (
													<Select.Option key={index} value={item.countryCode}>
														{item.country !== 'All' && (
															<img
																src={`https://flagsapi.com/${item.countryCode}/shiny/64.png`}
																alt='country'
																className='country-flag'
															/>
														)}
														{item.countryName}
													</Select.Option>
												))}
											</Select>
										</Form.Item>
										<Form.Item label={t<string>('paymentMethods')} className='xss:w-full md:w-[300px] my-0'>
											<Select
												defaultValue={''}
												value={paymentMethodFilter}
												onChange={(e: string) => setPaymentMethodFilter(e)}
												showSearch
												filterOption={(inputValue, option: any) =>
													option?.children ? option.children.toLowerCase().includes(inputValue.toLowerCase()) : false
												}
											>
												{paymentMethods.map((item, index) => (
													<Select.Option key={index} value={item.methodName}>
														{item.methodName}
													</Select.Option>
												))}
											</Select>
										</Form.Item>
									</div>
									<div className='flex my-0'>
										<Form.Item label={t<string>('limit')} className='w-100 md:w-[150px] mx-2 xss:mt-3 md:mt-0 my-0'>
											<Input
												type='number'
												placeholder={t<string>('min')}
												value={minLimitFilter}
												onChange={(e) => setMinLimitFilter(e.target.value)}
											/>
										</Form.Item>
										<span className='align-middle self-center -mb-1'>-</span>
										<Form.Item label={'  '} className='w-100 md:w-[150px] mx-2 xss:mt-3 md:mt-0 my-0'>
											<Input
												type='number'
												placeholder={t<string>('max')}
												value={maxLimitFilter}
												onChange={(e) => setMaxLimitFilter(e.target.value)}
											/>
										</Form.Item>
									</div>
								</div>
							</Form>
						</div>
					</Collapse.Panel>
				</Collapse>
				<div className='mb-0 items-center justify-between w-full  flex flex-col md:flex-row-reverse flex-wrap my-2'>
					<div className='flex md:w-1/4 w-full gap-2'>
						<Button
							onClick={refreshData}
							className='xss:h-8 md:h-10 xss:w-8 md:w-10  border-none flex items-center justify-center bg-[#EAECEF] rounded-[4px]'
						>
							<SyncOutlined />
						</Button>
						<Input
							id='merchants_search_input'
							className='xss:w-full sm:w-64 border-none xss:h-8 md:h-10 bg-[#F5F5F5] search-input'
							placeholder={t<string>('searchInMerchantsTable')}
							value={searchTerm}
							onChange={(e) => {
								setSearchTerm(e.target.value);
							}}
							size='large'
							prefix={<SearchOutlined />}
						/>
					</div>

					<div className='w-full md:w-[75%] flex justify-between md:flex-row'>
						<div className='flex xss:w-[20%] md:w-1/4 items-center my-2'>
							<span className='text-[#000D1D99] text-sm '>{t<string>('show')}</span>
							<Dropdown className='mx-[4px] md:mx-[10px]' trigger={['click']} menu={{ items: pageSizeItems }}>
								<span className='bg-[#F5F5F5] text-[#1E2329] rounded xss:h-8 md:h-10 xss:w-14 md:w-24 flex items-center justify-between px-2 cursor-pointer'>
									{pageSize}
									<CaretDownOutlined />
								</span>
							</Dropdown>
							<span className='text-[#000D1D99] text-sm '>{t<string>('entries')}</span>
						</div>
						<div className='flex justify-end items-center mx-2 '>
							<Dropdown trigger={['click']} menu={{ items: sortByItems }}>
								<span className='bg-[#F5F5F5] xss:h-8 md:h-10 md:min-w-[200px] flex items-center justify-between px-4 rounded-md text-[#1E2329] text-sm cursor-pointer'>
									{sortOrder === '' ? t<string>('sortBy') : getSortByValue(sortOrder, t)}
									<CaretDownOutlined />
								</span>
							</Dropdown>
						</div>
					</div>
				</div>
				<StyledPagination bgColor={themeColor} color={secondary_color}>
					<Table
						columns={getMerchantsColumns(
							countriesState.paymentMethods,
							isMerchant,
							offerType,
							t,
							sortBy,
							setSortBy,
							setMerchantID,
							setShowModal,
							mainState.userStatus || userStatus,
							!lg,
						)}
						dataSource={merchants}
						loading={merchantsLoading}
						pagination={{
							pageSize,
							total: totalElements,
							onChange: (pageIndex) => setPage(pageIndex - 1),
							current: page + 1,
						}}
						tableLayout='fixed'
						className={lg ? '' : 'hide-table-header'}
					/>
				</StyledPagination>
			</div>
			{showModal && merchantID && wallet && merchant && isLimitReached === false && (
				<MerchantBuyAndSellModal
					merchant={merchant}
					paymentMethods={countriesState.paymentMethods}
					wallet={wallet}
					closeModal={() => {
						setShowModal(false);
						setIsLimitReached(false);
					}}
					offerType={offerType}
					setRefersh={setRefersh}
					refersh={refresh}
				/>
			)}
			{isLimitReached && (
				<LimitCheckModal isLimitReached={isLimitReachedModal} setIsLimitReached={setIsLimitReachedModal} />
			)}
		</div>
	);
};

export default Merchants;
