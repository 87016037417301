function PlFlag() {
	return (
		<svg className='flag' xmlns='http://www.w3.org/2000/svg' version='1.1' id='flag-icons-pl' viewBox='0 0 640 480'>
			<g fill-rule='evenodd'>
				<path fill='#fff' d='M640 480H0V0h640z' />
				<path fill='#dc143c' d='M640 480H0V240h640z' />
			</g>
		</svg>
	);
}

export default PlFlag;
