import { useTranslation } from 'react-i18next';
import { Form, Input, Button, notification } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React from 'react';
import axios from 'axios';
import { getUserLanguageInCookie } from '../../helpers/cookiesHandler';
import './ContactSupport.scss';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import SubHeader from '../../components/SubHeader/SubHeader';
import { EMAIL_VALIDATION } from '../../assets/config/validation-regex';
import { useConfigurationContext } from 'store/configurationContext';
import { CompanyName } from 'constant/fallback-config';

function ContactSupport() {
	const { t } = useTranslation();
	const [contactSupportForm] = Form.useForm();
	const [contactSupportFormDisabled, setContactSupportFormDisabled] = React.useState(true);
	const [isContactSupportFormLoading, setIsContactSupportFormLoading] = React.useState(false);

	const { configurationState } = useConfigurationContext();
	const onContactSupportFormChange = () => {
		const { email, message, name, phone, subject } = contactSupportForm.getFieldsValue();
		const isEmailValid = EMAIL_VALIDATION.test(email);
		setContactSupportFormDisabled(!isEmailValid || !message || !name || !phone || !subject);
	};
	const onContactSupportFormSubmit = () => {
		const values = contactSupportForm.getFieldsValue();
		setIsContactSupportFormLoading(true);

		axios
			.post(API_ENDPOINTS.generalSupport, {
				...values,
				phone: `${values.phone}`,
				langId: getUserLanguageInCookie(),
			})
			.then(() => {
				setIsContactSupportFormLoading(false);
				setContactSupportFormDisabled(true);

				notification['success']({
					message: '',
					description: t<string>('requestWasSentSuccessfully'),
				});
			})
			.catch((error) => {
				console.error(error);
				setIsContactSupportFormLoading(false);
				setContactSupportFormDisabled(true);
			});
	};
	const Company_Name = configurationState.find((item) => item.configKey === 'Company_Name')?.value || '';

	return (
		<div className='contact-support-layout'>
			<SubHeader
				title={t<string>('contactSupport')}
				description={`Email: site.${Company_Name}@${Company_Name}.co`}
				subDescription='Suite 305, Griffith Corporate Centre, Beachmont, Kingstown, St. Vincent and Grenadines.'
			/>
			<section className='contact-support-container'>
				<Form
					name='basic'
					className='contact-support-form'
					form={contactSupportForm}
					layout='vertical'
					onValuesChange={onContactSupportFormChange}
					autoComplete='off'
					disabled={isContactSupportFormLoading}
					onFinish={onContactSupportFormSubmit}
				>
					<div className='my-offers-form-content'>
						<div>
							<div className='row-fields'>
								<Form.Item
									label={t<string>('yourName')}
									name='name'
									rules={[{ required: true, message: t<string>('nameIsRequired') }]}
								>
									<Input />
								</Form.Item>
								<Form.Item
									label={t<string>('yourEmail')}
									name='email'
									rules={[{ pattern: EMAIL_VALIDATION, message: t<string>('emailMsg'), type: 'email' }]}
								>
									<Input />
								</Form.Item>
							</div>
							<div className='row-fields'>
								<Form.Item
									label={t<string>('yourPhone')}
									name='phone'
									rules={[{ required: true, message: t<string>('phoneIsRequired') }]}
								>
									<Input />
								</Form.Item>
								<Form.Item
									label={t<string>('subject')}
									name='subject'
									rules={[{ required: true, message: t<string>('subjectIsRequired') }]}
								>
									<Input />
								</Form.Item>
							</div>
							<Form.Item
								label={t<string>('message')}
								name='message'
								rules={[{ required: true, message: t<string>('messageIsRequired') }]}
							>
								<TextArea rows={4} />
							</Form.Item>
							<Form.Item>
								<Button
									type='primary'
									htmlType='submit'
									disabled={contactSupportFormDisabled}
									loading={isContactSupportFormLoading}
								>
									{t<string>('sendMessage')}
								</Button>
							</Form.Item>
						</div>
					</div>
				</Form>
			</section>
		</div>
	);
}

export default ContactSupport;
