import { Popover, Button } from 'antd';
import { getUserLanguageInCookie, setUserLanguageInCookie } from '../../helpers/cookiesHandler';
import i18n from 'i18next';
import React, { useEffect } from 'react';
import ArFlag from '../../languages/flags/ar';
import EnFlag from '../../languages/flags/en';
import FrFlag from '../../languages/flags/fr';
import PlFlag from '../../languages/flags/pl';
import { getCountryFlag } from '../../helpers/getCountryFlag';
import { DownOutlined } from '@ant-design/icons';
import { useDirectionContext } from 'store/DirectionContext';
import { useConfigurationContext } from 'store/configurationContext';
import { useMainContext } from 'store/MainContext';
import { getUserInfo, setUserInfo } from 'helpers/localStorageHandler';
import axios from 'axios';
import { API_ENDPOINTS } from 'assets/api/endpoints';
import { useSearchParams } from 'react-router-dom';

function Languages() {
	const [selectedLanguage, setSelectedLanguage] = React.useState(getUserLanguageInCookie());
	const [searchParams] = useSearchParams();
	const [open, setOpen] = React.useState(false);
	const { setDirectionState } = useDirectionContext();
	const { configurationState } = useConfigurationContext();
	const { mainState, setMainState } = useMainContext();
	const userDate = getUserInfo();
	const default_language = configurationState.find((item) => item.configKey === 'default_language')?.value || '';

	const onLanguageChange = async (lang: string) => {
		i18n.changeLanguage(lang);
		setSelectedLanguage(lang);
		setUserLanguageInCookie(lang);
		try {
			if (mainState.isLoggedIn) await axios.put(API_ENDPOINTS.changeLanguage, { lang });
		} catch (err) {
			console.log(err);
		}
		setMainState({ ...mainState, language: lang });
		if (userDate) setUserInfo({ ...userDate, language: lang });
	};

	useEffect(() => {
		const selectedLangauge = getUserLanguageInCookie();
		const lang = mainState.language
			? mainState.language
			: selectedLangauge
			? selectedLangauge
			: default_language
			? default_language
			: 'en';
		setSelectedLanguage(lang);
		i18n.changeLanguage(lang);
		setUserLanguageInCookie(lang);
	}, [default_language, mainState]);

	useEffect(() => {
		const body = document.body;
		selectedLanguage !== 'ar' ? body.classList.add('ltr') : body.classList.add('rtl');
		setDirectionState(selectedLanguage === 'ar' ? 'rtl' : 'ltr');
	}, [selectedLanguage]);

	const secondary_color = configurationState.find((item) => item.configKey === 'secondary_color')?.value || '';
	return (
		<Popover
			placement={selectedLanguage === 'ar' ? 'topLeft' : 'topRight'}
			trigger='click'
			open={open}
			onOpenChange={(newOpen) => setOpen(newOpen)}
			content={
				<div className='flex flex-col'>
					<Button
						onClick={() => {
							onLanguageChange('ar');
							setOpen(false);
						}}
						className='flex items-center text-sm justify-between bg-none border-none p-0 w-24 shadow-none'
					>
						العربية
						<span className='w-5'>
							<ArFlag />
						</span>
					</Button>

					<Button
						onClick={() => {
							onLanguageChange('en');
							setOpen(false);
						}}
						className='flex items-center text-sm justify-between bg-none border-none p-0 w-24 shadow-none'
					>
						English
						<span className='w-5'>
							<EnFlag />
						</span>
					</Button>

					<Button
						onClick={() => {
							onLanguageChange('fr');
							setOpen(false);
						}}
						className='flex items-center text-sm justify-between bg-none border-none p-0 w-24 shadow-none'
					>
						Français
						<span className='w-5'>
							<FrFlag />
						</span>
					</Button>

					<Button
						onClick={() => {
							onLanguageChange('pl');
							setOpen(false);
						}}
						className='flex items-center text-sm justify-between bg-none border-none p-0 w-24 shadow-none'
					>
						Polski
						<span className='w-5'>
							<PlFlag />
						</span>
					</Button>
				</div>
			}
		>
			<Button className='p-0 m-0 border-none uppercase text-white text-xs  font-medium flex items-center hover:!text-white bg-transparent'>
				<span className='w-[21px]' style={{ display: 'flex' }}>
					{getCountryFlag(selectedLanguage)}
				</span>
				<div className='mx-[6px]' style={{ color: secondary_color }}>
					{selectedLanguage}
				</div>
				<DownOutlined className='w-2 ' style={{ fontSize: 10, color: secondary_color }} />
			</Button>
		</Popover>
	);
}

export default Languages;
