import { LanguagePhrases } from '../models/LanguagePhrases';

export const englishPhrases: LanguagePhrases = {
	yesterday: 'Yesterday',
	today: 'Today',
	daysAgo: 'Days ago',
	signIn: 'Sign In',
	home: 'Home',
	signinTitle: 'Sign in to your payment account',
	signinDesc: 'Sign in using the credentials for the client portal',
	email: 'Email',
	password: 'Password',
	forgotPassword: 'Forgot Password?',
	emailRequired: 'Email field should not be empty!',
	passwordRequired: 'Password field should not be empty!',
	somethingWrong: 'Something went wrong!',
	emailMsg: 'Please make sure your email is valid!',
	passwordMsg:
		'Please ensure your password is a minimum of 8 characters, and includes at least a capital letter, small letter, and one special character.',
	becomeMerchant: 'Become a Merchant',
	yourAccountIsInactive: 'Your account is inactive. Please contact your Admin to activate your it',
	regularUserHome: 'Regular User Home',
	merchantHome: 'Merchant Home',
	merchantsOffer: "Merchants' Offers",
	notificationsHeading: 'Notifications',
	notificationsSubtitle: 'Empowering You with Timely Alerts! Stay Connected, Stay Notified.',
	searchInMerchantsTable: 'Search In Merchants Table',
	problemLoadingListOfMerchants: 'There is some problem loading the list of merchants',
	name: 'Name',
	rating: 'Rating',
	totalReview: 'Total Review',
	ordersCompleted: 'Orders Completed',
	price: 'Price',
	available: 'Available',
	minLimit: 'Min Limit',
	maxLimit: 'Max Limit',
	buy: 'Buy',
	sell: 'Sell',
	accountVerification: 'Account Verification',
	problemLoadingMerchant: 'There is some problem loading merchant info',
	merchantInfo: 'Merchant Info',
	wantToBuy: 'I want to buy',
	wantToSell: 'I want to sell',
	amountIsRequired: 'Amount is required',
	willReceive: 'I will receive',
	willSend: 'I will send',
	paymentMethod: 'Payment Method',
	paymentMethodIsRequired: 'Payment method is required',
	valueInRangeError: 'Amount should be in this range:',
	merchants: 'Merchants',
	countryCurrency: 'Country/Currency',
	currency: 'Currency',
	paymentMethods: 'Payment Methods',
	myDetails: 'My Details',
	changePassword: 'Change Password',
	myDetailsForm: 'My Details Form',
	myProfile: 'My Profile',
	phone: 'Phone',
	address: 'Address',
	city: 'City',
	zip: 'Zip',
	state: 'State',
	country: 'Country',
	firstName: 'First Name',
	surname: 'Surname',
	registrationDate: 'Registration Date',
	newPassword: 'New password',
	currentPassword: 'Current password',
	confirmPassword: 'Confirm Password',
	updatePassword: 'Update Password',
	changePasswordForm: 'Change Password Form',
	yourPasswordGotUpdated: 'Your password got updated',
	somethingWentWrongWithUpdatingPassword: 'Something went wrong with updating password',
	pleaseMakeSurePasswordIsValid:
		'Please make sure your password includes at least one capital letter, small letter, special character and the length of the password should be at least 8',
	twoPasswordsDoNotMatch: 'The two passwords must be the same',
	myOffers: 'My Offers',
	yourSellerFormGotUpdated: 'Your seller form got updated',
	somethingWentWrongWithUpdatingSellerForm: 'Something went wrong with updating seller form',
	yourBuyerFormGotUpdated: 'Your buyer form got updated',
	somethingWentWrongWithUpdatingBuyerForm: 'Something went wrong with updating buyer form',
	sellOffer: 'Sell Offer',
	profitRatio: 'Profit ratio',
	profitRatioIsRequired: 'Profit ratio is required',
	profitRatioInRangeError: 'Profit ratio can not be greater than ',
	atleastSelectOneMethod: 'Please select atleast one payment method',
	maximumAmount: 'Maximum amount',
	valueMustBePositive: 'Value must be positive',
	mustBeGreaterThanMinValue: 'Amount must be greater than minimum value',
	minimumAmount: 'Minimum amount',
	mustBeLessThanMaxValue: 'Amount must be less than maximum value',
	visibleForUsers: 'Visible for users',
	no: 'No',
	yes: 'Yes',
	update: 'Update',
	buyOffer: 'Buy Offer',
	logout: 'Log out',
	companies: 'Companies',
	news: 'News',
	privacyPolicy: 'Privacy Policy',
	termsOfUse: 'Terms of Use',
	merchantsOfferSubtitle: 'Discover fee-free P2P deals and choose your preferred payment method on our platform today!',
	limit: 'Limit',
	countries: 'Countries',
	max: 'Max',
	min: 'Min',
	all: 'All',
	merchantProfile: 'Merchant Profile',
	availableLimit: 'Available / Limit',
	fees: 'Fees',
	reviews: 'Reviews',
	trade: 'Trade',
	buyers: 'Buyers',
	sellers: 'Sellers',
	termsAndConditions: 'Terms & Conditions',
	unitPrice: 'Unit Price',
	youSale: 'You sale',
	youReceive: 'You receive',
	selectPaymentMethods: 'Select payment methods',
	account: 'Account',
	note: 'Note',
	pleaseWriteYourNote: 'Please write your note',
	certainPaymentMethodsMayHaveFees:
		'Certain payment methods may have fees and daily limits set by the payment provider.',
	youPay: 'You pay',
	orders: 'Order(s)',
	welcomeBack: 'Welcome back',
	loginToYourAccount: 'Login to your account',
	pleaseEnterYourEmail: 'Please enter your email',
	enterPassword: 'Enter password',
	notRegistered: 'Not registered?',
	or: 'or',
	createAnAccount: 'Create an account',
	readOur: 'Read our',
	signinWarningNote:
		'WARNING: Unauthorized access to this system is forbidden and will be prosecuted by law. By accessing this system, you agree that your actions may be monitored if unauthorized usage is suspected',
	allRightsReserved: 'All Rights Reserved',
	availableIsRequired: 'Available is required',
	somethingWentWrongVerification:
		'Something went wrong, please make sure the documents you uploaded are accepted and your account is verified',
	myOrders: 'My Orders',
	myOrderSubtitle: 'History Repeats Itself: Effortless Reorders, Every Time!',
	calculatedPrice: 'Calculated Price',
	status: 'Status',
	dateRange: 'Date Range',
	sent: 'Sent',
	recieved: 'Revieved',
	active: 'Active',
	pending: 'Pending',
	completed: 'Completed',
	cancel: 'Cancel',
	inDispute: 'In dispute',
	id: 'ID',
	quantity: 'Quantity',
	amount: 'Amount',
	dateAndTime: 'Date & Time',
	searchOrder: 'Search Order',
	transferPaymentToSeller: 'Transfer payment to Seller',
	pendingSellerToReleasePayment: 'Pending Seller to Release Payment',
	orderNumber: 'Order number',
	timeCreated: 'Creation Date',
	activeOrder: 'Active Order',
	pendingOrder: 'Pending Order',
	completedOrder: 'Completed Order',
	cancelledOrder: 'Cancelled Order',
	buyFrom: 'BUY from',
	buyUSDFrom: 'BUY USD from',
	buyIQDFrom: 'BUY IQD from',
	orderCreatedConfirmation: 'The order is created. Please wait for system confirmation',
	orderWaitingMessage:
		"Waiting for payment confirmation from the seller to release order, 95% of this seller's order have been completed within 3 min.",
	orderCompletedMessage:
		'The order was successfully marked as complete. If you have any issue in Payment, contact support to resolve the issue.',
	youSuccessfullyBought: 'You successfully bought',
	IQDFrom: 'IQD from',
	thisOrderHasBeenCancelled: 'This order has been cancelled',
	thisOrderIsInDispute: 'This order is in Dispute',
	orderAmount: 'Order Amount',
	youPaid: 'You Paid',
	accountName: 'Account name',
	orderDetails: 'Order Details',
	payWithZenCash: 'Pay with ZenCash',
	selectPaymentCompleted: 'Select Payment Completed to notify Seller',
	paymentCompletedNotify: 'Payment Completed, notify Seller',
	paymentReceived: 'Payment received',
	BackToHome: 'Back to home',
	reorder: 'Reorder',
	contactSupport: 'Contact Support',
	cancelOrder: 'Cancel Order',
	completeYourPayment: 'Complete your payment?',
	note1: 'Please confirm that you have successfully transferred money to seller.',
	note2: 'Note: Don’t proceed if you haven’t completed payment/ This might result in suspension of amount.',
	rateTheMerchant: 'Rate the Merchant:',
	orderS: 'order(S)',
	lastSeen: 'Last seen',
	ago: 'ago',
	yourName: 'Your name',
	yourEmail: 'Your email',
	yourPhone: 'Your phone',
	subject: 'Subject',
	message: 'Message',
	nameIsRequired: 'Name is required',
	emailIsRequired: 'Email is required',
	phoneIsRequired: 'Phone is required',
	subjectIsRequired: 'Subject is required',
	messageIsRequired: 'Message is required ',
	sendMessage: 'Send message',
	bestSellers: 'Best Sellers',
	maxOrders: 'Max Orders',
	minOrders: 'Min Orders',
	importantNote: 'Important Note',
	confirm: 'Confirm',
	confirmContactingMerchantInChat:
		'Please make sure to contact the Merchant before making any payment. {{companyName}} will not be responsible for any kind of Loss.',
	myAccounts: 'My Accounts',
	myAccountsSubtitle: 'Get a clear overview of your P2P Tether USDt holdings, including balances and account details',
	tradingPlatform: 'Trading Platform',
	accountType: 'Account Type',
	accountGroup: 'Account Group',
	accountNumber: 'Account Number',
	balance: 'Balance',
	onHoldBalance: 'On Hold Balance',
	client: 'Client',
	emailUs: 'Email Us',
	merchant: 'Merchant',
	writeMessage: 'Write Your Message',
	availability: 'Availability',
	buyersTerm: "Buyer's Terms and Conditions",
	sellersTerm: "Seller's Terms and Conditions",
	bankDeposit: 'Bank Deposit Only',
	accHolder: 'Original Account Holder',
	thirdParty: 'Third Party Payment Not Accepted',
	wedFri: 'Wed-Fri',
	merchantReview: 'Merchant Review',
	apiNotFound: 'API Not Found',
	unableToAuthenticateTheUser: 'Invalid or expired session',
	somethingWentWrong: 'Something went wrong',
	constraintViolated: 'Constraint Violated',
	recordIsLinked: 'Record is linked',
	parametersCannotBeNull: 'Parameters cannot be null',
	methodArgumentsAreInvalid: 'Method arguments are invalid',
	fileUploadingError: 'File Uploading Error',
	parameterIsNotValid: 'Request parameter is not valid',
	requiredParametersAreMissing: 'Required parameters are missing',
	pleaseEnterValidEmailAddress: 'Please enter a valid email address',
	passwordDoesNotMatchRequirements:
		'Password does not match the requirements: minimum 8 and maximum 16 characters, include at atleast one capital letter, one small letter, one digit, and one special character',
	invalidMerchantOfferRequest: 'Invalid Merchant offer request',
	invalidTradingAccountGroup: 'Invalid Trading Account Group',
	countryIsNotAllowed: 'Country is not allowed in P2P system',
	orderNumberProvidedIsInvalid: 'Order ID provided is invalid',
	orderIsClosed: 'Order is closed',
	actionNotApplicable: 'Action not applicable',
	errorOccurredWhileProcessingYourRequest: 'Error occurred while processing your request',
	problemOccurredWhileLoadingRecords: 'Problem occurred while loading records',
	problemOccurredWhileLoadingRecord: 'Problem occurred while loading record',
	problemOccurredWhileAddingRecord: 'Problem occurred while adding record',
	problemOccurredWhileUpdatingRecord: 'Problem occurred while updating record',
	problemOccurredWhileDeletingRecord: 'Problem occurred while deleting record',
	recordAlreadyExists: 'Record already exists',
	noRecordFound: 'No record found',
	accessDeniedMissingKyc: 'Access denied because of missing Kyc',
	invalidCredentials: 'Invalid credentials',
	invalidPaymentMethod: 'Invalid payment method',
	invalidOfferType: 'Invalid offer type',
	orderIsInProgress: 'Order is in progress',
	orderReviewIsAlreadyPresent: 'Order Review is already present',
	orderIsClosedMoreThanWeekAgo: 'Order is closed more than a week ago',
	userIsInactive: 'User is inactive',
	userCountryNotAllowed: 'User country not allowed',
	orderIsExpiredOrClosed: 'Order is expired or closed',
	invalidOrderAction: 'Invalid Order Action',
	onlyOneOrderAllowedAtTime: 'Only one order allowed at a time',
	invalidTradingAccount: 'Invalid Trading Account',
	merchantBelongsToDifferentCountry: 'Cannot trade! Merchant belongs to a different country',
	orderAmountIsMoreThanAvailableBalance: 'Order amount is more than available balance!',
	merchantOfferNotAvailable: 'Merchant Offer not available!',
	amountDoesNotSatisfyAvailableLimit: 'Amount does not satisfy available limit!',
	iraq: 'Iraq',
	lebanon: 'Lebanon',
	saudi: 'Saudi Arabia',
	egypt: 'Egypt',
	jordan: 'Jordan',
	requestWasSentSuccessfully: 'Request was sent successfully',
	noReviewsForThisMerchant: 'No reviews for this merchant',
	problemLoadingListOfOrders: 'There was a problem loading the list of orders',
	new: 'New',
	cancelled: 'Cancelled',
	transferred: 'Transferred',
	sellerConfirmed: 'Seller Confirmed',
	cancellationRequest: 'Cancellation Request',
	timedOut: 'Timed Out',
	appealed: 'Appealed',
	rejected: 'Rejected',
	closed: 'Closed',
	myOffersSubtitle: 'These are the offers that will be presented to your clients on Merchant',
	merchantDetails: 'Merchant Details',
	merchantDetailsDescription:
		'Explore detailed merchant profiles and their reviews, offering valuable insights to enhance your P2P trading experience',
	onlyClientsCanStartOrders: 'Only clients can start orders',
	maxValueRange: 'Maximum value should be in between 1 - {{amount}}',
	noteMinLength: 'Note should be greater than 20 characters',
	noteMaxLength: 'Note should be less than 1000 characters',
	cantOpenOrderNows: `You can't start new orders because you have on incomplete order`,
	amountLessThanMerchantAvailable: 'Amount is Less Than Merchant Available',
	amountLessThanYourBalanceLimit: 'Amount should be less than your balance',
	orderWith: 'Order with',
	receivingPaymentFromBuyer: 'Receiving payment from buyer',
	releasePaymentToBuyer: 'Release payment to buyer',
	opened: 'Opened',
	appeal: 'Appeal',
	expired: 'Expired',
	placed: 'Placed',
	unknown: 'Unknown',
	pleaseWaitUntilMerchantAcceptOrder: 'Please wait until merchant accept the order',
	pleaseAcceptTheOrderBeforeItsExpired: 'Please accept the order before its expired',
	thisOrderHasBeenCanceled: 'This order has been canceled',
	thisOrderHasBeenExpired: 'This order has been expired',
	thisOrderHasBeenRejected: 'This order has been rejected',
	thisOrderIsInAppeal: 'This order is in appeal',
	theOrderStateIsUnknown: 'The order state is unknown',
	writeReviewAboutTheMerchant: 'Write a review about the merchant',
	reviewIsRequired: 'Review is required',
	paymentDetails: 'Payment details',
	paymentCompleted: 'Payment completed',
	accept: 'Accept',
	reject: 'Reject',
	finishedSuccessfully: 'Finished successfully',
	chatIsDisabled: 'Chat is disabled',
	areYouSureYouWantToAcceptOrder: 'Are you sure you want to accept this order?',
	cancelThisOrder: 'Cancel this order',
	inzoDoesnNotProvideAutomaticRefunds: `{{comapnyName}} doesn't provide automatic refunds. Don't cancel your order if you've paid but haven't received the refund Raise a dispute if you need help.`,
	waitForReceivingConfirmation: 'Please wait for the confirmation of receiving the money',
	gotIt: 'Got it',
	makeSureToContactMerchant:
		'Please make sure to contact the Merchant before making any payment. {{companyName}} will not be responsible for any kind of Loss.',
	makeSureToContactClient:
		'Please make sure to contact the Client before making any payment. {{companyName}} will not be responsible for any kind of Loss.',
	importNote: 'Important note',
	paymentCompletedNote1: 'Please confirm that you have successfully transferred money to seller.',
	paymentCompletedNote2: `if the payment is not completed, do not proceed. This could result in the account being suspended`,
	sucessfullyCompletedPaymentToSeller: `I have successfully completed the payment to the seller.`,
	paymentReceivedNote1: 'Please confirm that you have successfully received money from buyer.',
	paymentReceivedNote2: `Note: Don't proceed if you haven't received payment/ This might result in suspension of the account.`,
	conofirmYouReceivedPayment: 'Confirm that you have received the payment.',
	sucessfullyCompletedPaymentToBuyer: `I've successfully completed the payment to the buyer.`,
	areYouSureYouWantToReject: 'Are you sure you want to reject this order?',
	transferWasSentSuccessfully: 'The transfer was sent successfully to the other part',
	accepted: 'Accepted',
	finishedSuccessfuly: 'Finished Successfuly',
	typeMessageHere: 'Type a message here',
	markAllAsRead: 'Mark All As Read',
	chooseMerchantFromYourCountry: 'Choose a merchant from your country',
	feedbackOnThisOrder: 'Feedback on this order',
	visit: 'Visit',
	seeOrderDetails: 'See order details',
	pleaseWaitUntilThePaymentIsReleased: 'Please wait until the payment is released',
	waitUntilMerchantReleasesPayment: 'Wait until merchant releases the payment',
	waitUntilClientTransfersPayment: 'Wait until client transfers the payment',
	waitUntilMerchantConfirmsReceivingPayment: 'Wait until merchant confirms receiving the payment',
	waitUntilClientConfirmsReceivingPayment: 'Wait until client confirms receiving the payment',
	paymentTransfered: 'Payment transferred',
	inzoDoesNotProvideAutoRefund: `{{comapnyName}} doesn't provide automatic refunds. Please login to the receiving account to check the status of the payment.`,
	iReceivedAmountFromBuyer: 'I have received {{amount}}  from the buyer.',
	iCheckedPaymentFrom: 'I have checked that the payment is from {{userName}}',
	iAgreeToReleasePaymentToBuyer: 'I agree to release my payment to the buyer.',
	transferWasSentSuccessfullyClient: 'You successfully bought {{amount}} from {{username}}',
	transferWasSentSuccessfullyMerchant: 'You successfully sold {{amount}} to {{username}}',
	reviewSubmitted: 'Review submitted successfully',
	notificationNewOrderMessage: `New {{orderType}} order #{{orderNumber}} for an amount of {{amount}} was opened.`,
	notificationOrderNewMessage: `New message in order #{{orderNumber}} at {{createdAt}}`,
	notificationOrderExpiryWarning: `Your order {{orderNumber}} is about to expire at {{orderExpiresTimestamp}}`,
	notificationOrderExpired: `Order #{{orderNumber}} has expired.`,
	notificationOrderStatusUpdate: `Status change for order {{orderNumber}}. {{orderStatusBefore}} → {{orderStatusAfter}}`,
	notificationOfferDisabled: `Your {{offerType}} offer has been taken down due to {{reason}}`,
	notificationAccountBalanceLow: `Your merchant’s account balance is running low. Current balance: {{amount}}`,
	notificationSellAvailableLow: `The sell offer amount you specified is about to run out. Update the amount in order for the offer to remain valid. Current available sell balance: {{sellAvailable}}`,
	seeMore: 'See more',
	noNotificationsFound: 'No Notifications Found',
	totalOrder: 'Total Orders',
	offerType: 'Offer Type',
	offerNotAvailable: 'This offer is not available',
	errorProccessingMT5: 'Error occurred while processing Trading Account request.',
	amountDosentSatisfyLimit: 'Amount does not satisfy offer allowed limit!',
	noteErrorMessage: 'Note should not longer than 1000 characters',
	type: 'Type',
	resonsForCancellation: 'Reason for cancellation',
	resonsForCancellationNote:
		'To avoid loss, do not cancel the order after you have made the payment. {{comapnyName}}  is not responsible for any kind of loss',
	unableToMakePayment: 'Unable to make payment',
	dontKnowHowToPay: 'Do not know how to pay',
	discussedWithSeller: 'Discussed with seller not to proceed',
	poorSellerAttitude: 'Poor seller attitude',
	cantContactSeller: 'Can’t contact seller',
	suspectSellerFraud: 'Suspect seller of fraud',
	others: 'Others',
	userSuspend: 'Your account has been suspended.',
	userUnsuspend: 'Your account has been un-suspended.',
	userBlock: 'Your account has been blocked.',
	userUnblock: 'Your account has been un-blocked.',
	accessDenied: 'Access denied',
	chatHasBeenClosed: 'Chat has been closed',
	emailAlreadyExist: 'Email already exist',
	phoneNumberDoesNotMatchTheRequirement: 'Phone number does not match the requirement',
	newPasswordAndConfirmPasswordNotSame: 'New password and confirm password not same',
	userIsBlocked: 'User is blocked',
	userIsSuspended: 'User is suspended',
	invalidOTP: 'Invalid One Time Password',
	merchantNotFound: 'Merchant not found',
	cancelAppeal: 'Cancel Appeal',
	newReview: 'New Review',
	merchantNewReview: 'Merchant New Review',
	revert: 'REVERT',
	release: 'RELEASE',
	copiedSuccessfully: 'Copied Successfully',
	userSuspended: 'User Suspended',
	withdrawal: 'Withdrawal',
	deposit: 'Deposit',
	chatnow: 'Chat Now',
	expireIn: 'Expire In',
	releasePayment: 'Release Payment To Buyer',
	paywith: 'Pay with {{method}}',
	notFound: 'Order Not Found',
	invalidType: 'Invalid file extension. Please upload a JPG, JPEG, PNG, or PDF file.',
	fileExceedLimit: 'File size exceeds the allowed limit (5MB).',
	accountLimit: 'Account Limit',
	maxValueAvailable: 'The amount is bigger than the available',
	cannotEnableOffer: 'Can not Enable Offer Due to Low Balance',
	show: 'Show',
	entries: 'Entries',
	sortBy: 'Sort By',
	startDate: 'Start Date',
	endDate: 'End Date',
	enterYourOtp: 'Enter your email verification code',
	amountmustbelessThanavailabel: 'Amount must be less than Available Balance {{amount}} {{currency}}',
	amountmustbeBiggerThanMinmum: 'Amount must be Bigger Than the minimum',
	minAllowedValue: 'Minimum Amount must be greater than or equal to {{amount}} {{currency}}',
	maxAllowedValue: 'Minimum Amount must be greater than or equal to {{amount}} {{currency}}',
	priceLow: 'Price Low-High',
	priceHight: 'Price High-Low',
	didNotreceiveOtp: 'Didn’t get the code?',
	resend: 'Resend',
	withdrawalOffer: 'Withdrawal Offer',
	depositOffer: 'Deposit Offer',
	NEW_ORDER: 'New Order',
	ORDER_NEW_MESSAGE: 'Order New Message',
	ORDER_EXPIRY_WARNING: 'Order Expiry Warning',
	ORDER_EXPIRED: 'Order Expired',
	ORDER_STATUS_UPDATE: 'Order Status Update',
	OFFER_DISABLED: 'Offer Disabled',
	ACCOUNT_BALANCE_LOW: 'Account Balance Low',
	SELL_AVAILABLE_LOW: 'Sell Available Low',
	MERCHANT_NEW_REVIEW: 'Merchant New Review',
	USER_SUSPEND: 'User Suspend',
	USER_UNSUSPEND: 'User Unsuspend',
	USER_BLOCK: 'User Block',
	USER_UNBLOCK: 'User Unblock',
	unitPriceChange: 'Unit price is changed. Please review new prices before placing order',
	allowBalance: 'Please deposit {{amount}}  USD to enable offers.',
	priceChange: 'Price Change',
	YouReceive: 'You Recieve',
	Youpay: 'You Pay',
	Seeorderdetails: 'See order details',
	INSUFFICIENT_BALANCE: 'Insufficient Balance',
	COOLDOWN: 'Cooldown',
	BY_ADMIN: 'By Admin',
	termsAndConditionError: 'Merchant has not accepted terms and conditions',
	termsAndConditionsTitle: 'Terms And Conditions webatts Merchant',
	acceptTerms: 'I accept the terms and conditions',
	aggree: 'Agree',
	RegistrationAndqualification: 'Registration And qualification',
	RegistrationAndqualification1:
		'Merchants must register as webatts Merchant and provide the required information for qualification.',
	RegistrationAndqualification2:
		'Merchants must provide valid identity documents with proof of residence, and a meeting will be held with the merchant to authenticate his account.',
	RegistrationAndqualification3:
		'Qualification is subject to company approval, and conditions can be modified at any time.',
	TransactionsAndfees: 'Transactions and fees',
	TransactionsAndfees1: 'Merchants must comply with the approved fees and rates for the webatts Merchant service',
	TransactionsAndfees2: 'The company has the right to update fees and prices and notify merchants of this.',
	CommitmentTosafety: 'Commitment to safety',
	CommitmentTosafety1: 'Merchants must take strict security measures to protect customer information and transactions',
	CommitmentTosafety2: 'A safe and secure environment must be provided to conduct transactions.',
	Compliancewithlawsandregulations: 'Compliance with laws and regulations',
	Compliancewithlawsandregulations1:
		'Merchants must comply with all laws and regulations related to financial services and webatts Merchant',
	Positiveinteractionwithcustomers: 'Positive interaction with customers',
	Positiveinteractionwithcustomers1:
		'Merchants must interact positively with customers and provide effective support and speed in executing orders.Complaints and problems must be resolved quickly and effectively.',
	Terminationofservice: 'Termination of service',
	Terminationofservice1:
		'The company reserves the right to terminate the merchant`s service if the agreed upon terms and conditions are not complied with.',
	send: 'Send',
	amountDoesNotSatisfyAccountGroupLimit: 'Amount Dose not satisfy account group Limit',
	limitReached: 'Merchant Order Limit Reached!',
	OTPIsrequired: 'OTP is required!',
	Pleaseuploadthepaymentproof: 'Please upload the payment proof',
	ChooseFile: 'Choose File',
	selectPaymentProof: 'select Payment Proof',
	fileUploadRequired: 'File is required',
	download: 'Download',
	live: 'Live',
	submit: 'Submit',
	back: 'Back',
	of: 'of',
	items: 'items',
	agent: 'Agent',
	tradersRoom: 'Traders Room',
	exchangeRateUpdate: 'Exchange rate has been changed from {{oldExchangeRate}} to {{newExhangeRate}} by {{role}}',
	EXCHANGE_RATE_UPDATE: 'Exchange Rate Update',
	ORDER_REJECTION_WARNING: 'Order Rejection Warning',
	sessionExpired: 'Session Expired',
	orderRejectionWarning: 'Rejecting order one more time will disable your offers',
	suspendClient: 'Suspend Client',
	suspendMerchant: 'Suspend Merchant',
	unsuspendMerchant: 'Unsuspend Merchant',
	unsuspendClient: 'UnSuspend Client',
	restricted: 'Restricted',
	completionRate: 'Completion',
	otpexpired: 'OTP has expired!',
	reachedotplimit: 'You have reached maximum attempts for OTP!!',
	annoncement: 'Scheduled portal maintenance from {{from}} - {{to}}',
	createdAt: 'Created at',
	searchWallet: 'Search Transaction',

	WALLET_BALANCE: 'Wallet balance',
	WALLET: 'Wallet',
	WALLET_DESCRIPTION: 'Streaming Transactions, Empowering Connections: Your Wallet for Seamless P2P Exchanges',
	WITHDRAW_FROM_MT5: 'Withdraw from Trading Account',
	DEPOSIT_TO_MT5: 'Deposit to Trading Account',
	WALLET_AMOUNT: 'Wallet Balance',
	WITHDRAW_COMMISSION: 'Withdraw commission',
	TRANSACTION_REPORT: 'Transaction Report',
	TRANSACTION_REPORT_NOTE: 'Please note that you will receive an email once it is completed.',
	WALLET_ACCOUNTS: 'Wallet Accounts',
	MY_WALLETS: 'My Wallets',
	WALLET_NUMBER: 'Wallet Number',
	ON_HOLD_BALANCE: 'On Hold Balance',

	PENDING: 'Pending',
	COMPLETED: 'Completed',
	REVERTED: 'Reverted',
	PENDING_APPROVAL: 'Pending Approval',
	CANCELLED: 'Cancelled',
	P2P_BUY: 'P2P Buy',
	P2P_SELL: 'P2P Sell',
	P2P_REFUND: 'P2P Refund',
	TRADING_DEPOSIT: 'Trading Deposit',
	TRADING_WITHDRAW: 'Trading Withdrawal',
	EXCHANGE: 'Exchange',
	MANUAL: 'Manual',
	p2pBuyOrder: 'P2P Buy Order',
	p2pSellOrder: 'P2P Sell Order',
	depositToTradingAccount: 'Deposit To Trading Account',
	withdrawFromTradingAccount: 'Withdraw From Trading Account',
	amountshouldliebetween: 'Amount should lie between wallet limit {{min}} - {{max}} {{currency}}',

	withdrawRequesttitle: 'Withdraw from Trading Account ',
	withdrawRequestSubtitle: 'The amount will be credited to your wallet in sometime',
	depositRequestTitle: 'Deposit to Trading Account',
	depositRequestSubtitle: 'The amount will be credited to your Trading Account within a minute.',
	commisionPercentage: 'Commission Percentage',
	bounsPercentage: 'Bonus Percentage',
	withdrawAmount: 'Withdraw Amount',
	depositAmount: 'Deposit Amount',
	commisionAmount: 'Equivalent Commission Amount',
	bounsAmount: 'Equivalent Bonus Amount',
	withdrawRequest: 'Withdraw Request',
	depositRequest: 'Deposit',
	transactionId: 'Transaction ID',
	date: 'Date',

	withdrawCommission: 'Withdraw Commission',
	depositBouns: 'Deposit Bouns',

	Currencycodealreadyexist: 'Currency code already exist',
	Walletnotexist: 'Wallet not exist',
	Walletbalanceunsatisfied: 'Wallet balance unsatisfied',
	Merchantwalletscurrencyisdifferent: 'Merchant wallets currency is different',
	Walletonholdbalanceunsatisfied: 'Wallet on hold balance unsatisfied',
	Erroroccurredwhiledoingtransaction: 'Error occurred while doing transaction',
	Youarenotallowedtoperformthisaction: 'You are not allowed to perform this action',
	OrderWalletTransaction: 'Order Wallet Transaction',

	NEW_OPERATION: 'New Operation',
	REQUEST_ACTION: 'Request Action',
	requestAction: 'Your {{transactionType}} request of {{amount}} has been {{requestState}}',
	newOperation: 'You made {{transactionType}} request of {{amount}}',
	newOperationDeposit: 'You made {{transactionType}} of {{amount}}',
	depositWithdrawRequest: 'I agree to {{type}} {{amount}} to Trading Account.',
	WithdrawRequest: 'I agree to withdraw request of {{amount}} from Trading Account',
	amountshouldLessThanwalletAvailable: 'Amount should less than wallet balance {{amount}}',
	credit: 'Credit',
	holdon: 'Hold On',
	youAreBeignRedirect: 'You are being redirected to P2P...',
	validationChatMessage: 'special characters <, >, & are not allowed.',
	youAreBeingRedirectBack: 'You are Being Redirect Back ...',
	showLiveChat: 'Show Live Chat',
	hideLiveChat: 'Hide Live Chat',
	noTradingAccountFound: 'No Trading Account was found.',
	otpSendMessage: 'Otp sent Successfully',
	amountNotSatisfyOfferLimit: 'Amount not satisfy merchant offer limit {{min}} - {{max}} {{currency}}',
	amountLessMerchnat: 'Amount should be less than merchant availble balance {{available}} {{currency}}',
	otpResendMessage: 'OTP Resent Successfully',
	UploadLivePicture: 'Upload Live Picture',
	Tips: 'Tips',
	tipOne: 'Ensure your data are clearly visible and not obscured.',
	tipTwo: 'Take the photo in a well-lit area to avoid shadows or glare.',
	tipThree: 'Photos that are blurry or out of focus can make it challenging to verify.',
	tipFour: 'Avoid taking the photo at an angle that distorts the image.',
	FileExtensionNotSupported: 'File Extension Not Supported',
	FileTooLarge: 'File is too large',
	InvalidFile: 'Invalid File',
	ADMIN_WALLET_DEPOSIT: 'Admin Wallet Deposit',
	ADMIN_WALLET_WITHDRAW: 'Admin Wallet Withdraw',
	recaptchaError: 'Your request could not be completed. Please refresh the page and try again',
	termsAndConditionsBuyers: 'Buyers Terms & Conditions',
	termsAndConditionsSellers: 'Sellers Terms & Conditions',
	SimultaneousTransactions: 'Simultaneous transactions Not Allowed',
	availableDeposit: 'Available',
	minimumAmountDeposit: 'Minimum Amount',
	maximumAmountDeposit: 'Maximum Amount',
	availableWithdraw: 'Available',
	minimumAmountWithdraw: 'Minimum Amount',
	maximumAmountWithdraw: 'Maximum Amount',
	'Currency/Wallet': 'Currency/Wallet',
	'Filter Options': 'Filter Options',
	languageError: 'Impossible de mettre à jour la même langue',
	'TradingAccount(s)NotSupported': 'Trading Account(s) Not Supported in P2P System',
};
