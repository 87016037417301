import { NotificationActionType } from './getNotificationsActionTypes';

export function getNotificationType(notification: string): string {
	switch (notification) {
		case NotificationActionType.NEW_ORDER:
		case NotificationActionType.ORDER_NEW_MESSAGE:
		case NotificationActionType.ORDER_STATUS_UPDATE:
		case NotificationActionType.MERCHANT_NEW_REVIEW:
		case NotificationActionType.USER_BLOCK:
		case NotificationActionType.USER_SUSPEND:
		case NotificationActionType.USER_UNBLOCK:
		case NotificationActionType.USER_UNSUSPEND:
			return 'ACTION';
		case NotificationActionType.ORDER_EXPIRY_WARNING:
		case NotificationActionType.OFFER_DISABLED:
		case NotificationActionType.ORDER_EXPIRED:
		case NotificationActionType.ACCOUNT_BALANCE_LOW:
		case NotificationActionType.SELL_AVAILABLE_LOW:
		case NotificationActionType.EXCHANGE_RATE_UPDATE:
		case NotificationActionType.ORDER_REJECTION_WARNING:
			return 'WARNING';
		case NotificationActionType.NEW_OPERATION:
		case NotificationActionType.REQUEST_ACTION:
			return 'INFO';
		default:
			return 'ACTION';
	}
}
