import { Checkbox, Modal, Spin } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function AcceptDialog({
	showAcceptDialog,
	setShowAcceptDialog,
	handelAccept,
	loading,
}: {
	showAcceptDialog: boolean;
	setShowAcceptDialog: (value: boolean) => void;
	handelAccept: () => void;
	loading: boolean;
}) {
	const { t } = useTranslation();
	const [isAcceptOrderChecked, setIsAcceptOrderChecked] = useState(false);

	return (
		<Modal onCancel={() => setShowAcceptDialog(false)} open={showAcceptDialog} footer={null}>
			<Checkbox
				id='order-life-cycle_accept-dialog_accept-order'
				checked={isAcceptOrderChecked}
				onChange={(e) => setIsAcceptOrderChecked(e.target.checked)}
				style={{ marginBottom: '20px' }}
			>
				{t<string>('areYouSureYouWantToAcceptOrder')}
			</Checkbox>
			<Spin spinning={loading}>
				<button
					id='order-life-cycle_accept-dialog_accept-button'
					disabled={!isAcceptOrderChecked}
					className='form-btn'
					style={{ cursor: 'pointer', margin: '0 auto', maxWidth: '100px', height: '36px', fontWeight: 400 }}
					onClick={() => {
						handelAccept();
						setShowAcceptDialog(false);
						setIsAcceptOrderChecked(false);
					}}
				>
					{t<string>('yes')}
				</button>
			</Spin>
		</Modal>
	);
}
