import React, { useState } from 'react';
import { Upload, Button, notification } from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';
import { UploadChangeParam } from 'antd/lib/upload/interface';
import './UploadFile.scss';
import { useTranslation } from 'react-i18next';

const allowedMimeTypes = ['image/jpeg', 'image/png', 'application/pdf'];
const maxFileSize = 5 * 1024 * 1024;

const FileUpload: React.FC<{ onFileSelected: (value: any) => void }> = ({ onFileSelected }) => {
	const [file, setFile] = useState<any>();
	const [selectedFileName, setSelectedFileName] = useState<string>('');

	const { t } = useTranslation();
	const handleChange = (info: UploadChangeParam) => {
		let file = info.file;
		setFile(file);
		onFileSelected(file);
		if (file) {
			let tremName = file.name.split('.');
			let name = tremName[0].length > 15 ? tremName[0].slice(0, 15) + '....' + tremName[1] : file.name;
			setSelectedFileName(name);
		} else {
			setSelectedFileName('');
		}
	};

	return (
		<div className='upload-file-container'>
			<div className='selected-file' style={{ border: selectedFileName ? '2px solid #018aff' : '2px solid #bfbfbf' }}>
				{selectedFileName ? selectedFileName : t<string>('selectPaymentProof')}
			</div>
			<Upload
				previewFile={file}
				onChange={handleChange}
				beforeUpload={() => false} // Disable automatic upload
				showUploadList={false}
				multiple={false}
				id='order-life-cycle_upload-file'
			>
				<Button
					style={{ background: '#018aff', color: 'white', borderRadius: '0px', border: '1px solid #018aff' }}
					icon={<CloudUploadOutlined />}
				>
					{t<string>('ChooseFile')}
				</Button>
			</Upload>
		</div>
	);
};

export default FileUpload;
