import './SelectPaymentMethods.scss';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';

const SelectPaymentMethods = ({ id, methods, selectedMethod, setSelectedMethod }: any) => {
	const { t } = useTranslation();

	return (
		<div>
			<p className='payment-methods-label'>{t<string>('paymentMethods')}</p>
			<Select
				id={id ?? null}
				className='modal-payment-methods'
				defaultValue={''}
				value={selectedMethod}
				onChange={(e: string) => setSelectedMethod(e)}
			>
				{methods.map((item: any, index: any) => (
					<Select.Option id={`payment-method-${index}`} key={index} value={item.methodId}>
						{item.methodName}
					</Select.Option>
				))}
			</Select>
		</div>
	);
};

export default SelectPaymentMethods;
