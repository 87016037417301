export enum WalletType {
	P2P_BUY = 'P2P_BUY',
	P2P_SELL = 'P2P_SELL',
	P2P_REFUND = 'P2P_REFUND',
	TRADING_DEPOSIT = 'TRADING_DEPOSIT',
	TRADING_WITHDRAW = 'TRADING_WITHDRAW',
	EXCHANGE = 'EXCHANGE',
	MANUAL = 'MANUAL',
	ADMIN_WALLET_DEPOSIT = 'ADMIN_WALLET_DEPOSIT',
	ADMIN_WALLET_WITHDRAW = 'ADMIN_WALLET_WITHDRAW',
}
