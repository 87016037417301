import { useTranslation } from 'react-i18next';
import { WalletStatus } from '../types/WalletStatus';

export const TransactionStatus = ({ id, status }: { id?: string; status: WalletStatus }) => {
	const { t } = useTranslation();

	switch (status) {
		case WalletStatus.COMPLETED:
			return (
				<div id={id} className='text-green-500'>
					{t(status)}
				</div>
			);
		case WalletStatus.PENDING:
			return (
				<div id={id} className='text-cyan-500'>
					{t(status)}
				</div>
			);
		case WalletStatus.PENDING_APPROVAL:
			return (
				<div id={id} className='text-yellow-500'>
					{t(status)}
				</div>
			);
		case WalletStatus.REVERTED:
			return (
				<div id={id} className='text-red-500'>
					{t(status)}
				</div>
			);
		case WalletStatus.CANCELLED:
			return (
				<div id={id} className='text-gray-500'>
					{t(status)}
				</div>
			);
		default:
			return <div id={id}>{t(status)}</div>;
	}
};
