import './SubmittedFeedback.scss';
import { Badge, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { LikeFilled, DislikeFilled } from '@ant-design/icons';
import dayjs from 'dayjs';
import { OrderFeedbackType } from '../../../../../types/OrderFeedbackType';

const DATE_TIME_FORMAT = 'MMM D, YYYY h:mm:ss A';

function SubmittedFeedback({ feedback }: { feedback: OrderFeedbackType }) {
	const { t } = useTranslation();

	return (
		<div className='submitted-feedback-container'>
			<Badge.Ribbon
				text={feedback?.isLike ? <LikeFilled /> : <DislikeFilled />}
				color={feedback?.isLike ? '#33a867' : '#f6465d'}
				style={{ fontSize: '18px', lineHeight: 2 }}
			>
				<Card
					title={
						<>
							<h3 className='feedback-title'>{t<string>('feedbackOnThisOrder')}</h3>
							<p className='feedback-date'>{dayjs(feedback?.createdAt).format(DATE_TIME_FORMAT)}</p>
						</>
					}
				>
					{feedback?.comment}
				</Card>
			</Badge.Ribbon>
		</div>
	);
}

export default SubmittedFeedback;
