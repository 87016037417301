interface Props {
	active: boolean;
}

const Sell = ({ active }: Props) => {
	return (
		<svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				id='Vector'
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M10.8002 6C10.8002 8.65098 8.65118 10.8 6.00021 10.8C3.34923 10.8 1.20021 8.65098 1.20021 6C1.20021 3.34903 3.34923 1.2 6.00021 1.2L6.00021 -2.62268e-07C2.68653 -4.07114e-07 0.000213029 2.68629 0.000212884 6C0.000212739 9.31368 2.68653 12 6.00021 12C9.31392 12 12.0002 9.31368 12.0002 6L10.8002 6ZM9.95168 0.6L10.8002 1.44853L6.84874 5.4L9.00012 5.4L9.00012 6.6L6.00009 6.6L4.80009 6.6L4.80009 3L6.00009 3L6.00009 4.55156L9.95168 0.6Z'
				fill={active ? 'white' : '#0F2147'}
			/>
		</svg>
	);
};

export default Sell;
