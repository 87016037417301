import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

type WalletAmountProps = {
	amount: string;
};

export const WalletAmount = (props: WalletAmountProps) => {
	const [isAmountVisible, setIsAmountVisible] = useState(false);
	const { t } = useTranslation();

	const toggleAmountVisibility = useCallback(() => {
		setIsAmountVisible((isAmountVisible) => !isAmountVisible);
	}, []);

	return (
		<article className='flex items-center justify-between p-3  border-none shadow-multi rounded w-full text-sm xss:w-full sm:w-72'>
			<div className='font-bold'>{t('WALLET_AMOUNT')}</div>
			<div className='flex items-center gap-2'>
				<span>{isAmountVisible ? <span id='wallet_amount'>{props.amount?.toLocaleString()}</span> : '******'}</span>
				<span id='wallet_amount-toggle-button' className='cursor-pointer' onClick={toggleAmountVisibility}>
					{isAmountVisible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
				</span>
			</div>
		</article>
	);
};
