import { useMemo } from 'react';
import { getActionStateConfiguration } from '../../../helpers/getStateConfiguration';
import { useTranslation } from 'react-i18next';
import './State.scss';
import { ActionType } from '../../../types/ActionType';

function ActionState({ actionState, size }: { actionState: ActionType; size: 'big' | 'small' }) {
	const { t } = useTranslation();
	const stateConfig = useMemo(() => getActionStateConfiguration(actionState, t), [t, actionState]);

	return (
		<span
			className='state'
			style={{
				color: stateConfig.color,
				backgroundColor: stateConfig.bgColor,
				border: 'none',
				fontSize: size === 'small' ? '10px' : '14px',
				marginBottom: '4px',
			}}
		>
			{stateConfig.label}
		</span>
	);
}

export default ActionState;
