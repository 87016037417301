/**
 * Converts a base64 string to a File object.
 * @param base64Data - The base64 encoded data string (including the data URL scheme).
 * @param fileName - The name to give the resulting file.
 * @returns A File object.
 */
export function base64ToFile(base64Data: string, fileName: string): File {
	// Remove the base64 header (data:image/png;base64, or other MIME types)
	const base64Header = base64Data.split(',')[0];
	const mime = base64Header.match(/:(.*?);/)?.[1] || ''; // Get MIME type or fallback to empty string
	const base64String = base64Data.split(',')[1]; // Get the base64 data

	// Decode base64 data
	const byteString = atob(base64String);

	// Create an array of byte values
	const byteArray = new Uint8Array(byteString.length);
	for (let i = 0; i < byteString.length; i++) {
		byteArray[i] = byteString.charCodeAt(i);
	}

	// Create a Blob from the byte array and specify the MIME type
	const blob = new Blob([byteArray], { type: mime });

	// Convert Blob to File
	const file = new File([blob], fileName, { type: mime });

	return file;
}
