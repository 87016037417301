import { OrderFeedbackType } from '../../types/OrderFeedbackType';
import { ActionType } from '../../types/ActionType';
import { OrderStateType } from '../../types/OrderStateType';
import { OrderType } from '../../types/OrderType';
import BodyDetails from './BodyDetails/BodyDetails';
import ChatContainer from './ChatContainer/ChatContainer';
import Stomp from 'stompjs';
import './OrderLifeCycleBody.scss';
import { useState } from 'react';
import { OrderDetailsType } from 'pages/OrderLifeCycle/types/OrderDetailsType';
import { WalletDetails } from 'models/WalletDetails';
import { OrderSource } from 'pages/OrderLifeCycle/types/OrderSource';

type OrderLifeCycleBodyProps = {
	orderState: OrderStateType;
	orderNumber: string;
	isMerchant: boolean;
	userName: string;
	orders: number;
	reviews: number;
	rating: number;
	feedback: OrderFeedbackType;
	orderBody: {
		unitPrice: string;
		quantity: string;
		amount: string;
		paymentMehodId: number;
		accountNumber: string;
		accountGroup: string;
	};
	actions: ActionType[];
	orderType: OrderType;
	merchantId: string;
	note: string;
	canReview: boolean;
	triggerAction: (action: ActionType, reasons?: string, otp?: string) => void;
	stompClient: Stomp.Client | null;
	getOrderDetails: () => void;
	clientOrder: number;
	paymentProof: boolean;
	paymentProofFileId: string;
	paymentProofFileLabel: string;
	otpVlaid: { value: boolean };
	loading: boolean;
	clientCompletedOrders: number;
	merchantDetails: OrderDetailsType['merchant'];
	walletDetails: WalletDetails;
	source: OrderSource;
};

function OrderLifeCycleBody({
	orderState,
	isMerchant,
	orderNumber,
	orderBody,
	userName,
	orders,
	reviews,
	rating,
	feedback,
	actions,
	orderType,
	merchantId,
	note,
	triggerAction,
	stompClient,
	getOrderDetails,
	clientOrder,
	canReview,
	paymentProof,
	paymentProofFileId,
	paymentProofFileLabel,
	otpVlaid,
	loading,
	clientCompletedOrders,
	merchantDetails,
	walletDetails,
	source,
}: OrderLifeCycleBodyProps) {
	const [isActionsDisabled, setIsActionsDisabled] = useState(true);
	const [isActionsHidden, setIsActionsHidden] = useState(false);

	return (
		<div className='order-life-cycle-body-main-wrapper'>
			<div className='order-life-cycle-body-wrapper'>
				<BodyDetails
					orderState={orderState}
					orderNumber={orderNumber}
					unitPrice={orderBody.unitPrice}
					quantity={orderBody.quantity}
					amount={orderBody.amount}
					feedback={feedback}
					paymentMehodId={orderBody.paymentMehodId}
					accountNNumber={orderBody.accountNumber}
					accountGroup={orderBody.accountGroup}
					actions={actions}
					orderType={orderType}
					merchantId={merchantId}
					userName={userName}
					note={note}
					isActionsDisabled={isActionsDisabled}
					isActionsHidden={isActionsHidden}
					triggerAction={triggerAction}
					getOrderDetails={getOrderDetails}
					canReview={canReview}
					paymentProof={paymentProof}
					paymentProofFileId={paymentProofFileId}
					paymentProofFileLabel={paymentProofFileLabel}
					otpVlaid={otpVlaid}
					loading={loading}
					stompClient={stompClient}
					walletDetails={walletDetails}
					source={source}
				/>
				<ChatContainer
					orderNumber={orderNumber}
					isMerchant={isMerchant}
					orderState={orderState}
					userName={userName}
					orders={orders}
					reviews={reviews}
					rating={rating}
					stompClient={stompClient}
					getOrderDetails={getOrderDetails}
					setIsActionsDisabled={setIsActionsDisabled}
					setIsActionsHidden={setIsActionsHidden}
					clientOrder={clientOrder}
					clientCompletedOrders={clientCompletedOrders}
					merchantDetails={merchantDetails}
				/>
			</div>
		</div>
	);
}

export default OrderLifeCycleBody;
